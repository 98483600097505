<template>
  <div class="test-backGround">
    <!-- ----------------------USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="userEditModal"
      role="dialog"
      aria-labelledby="userEditModal"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 id="editTitle2">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                Please fill the form if you want to edit the current User
                <br />
                <br />
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input3 form-control"
                  name="email"
                  id="emailInput"
                  disabled="disabled"
                  :placeholder="this.currentUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="firstNameInput"
                  class="new-user-input3 form-control"
                  :placeholder="this.currentUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="lastNameInput"
                  class="new-user-input3 form-control"
                  :placeholder="this.currentUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password"
                  >Password:&nbsp;
                  <a
                    href="#"
                    title="Password must contain: 
  -at least 8 characters 
  -at least 1 number
  -at least 1 lowercase character
  -at least 1 uppercase character"
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInput"
                  class="new-user-input3 form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('password')"
                  class="eyeButtonListPage"
                  id="showPasswordButtonListPage"
                >
                  <i class="fa-solid fa-eye" id="pwEyeListPage"></i>
                  <i class="far fa-eye-slash" id="pwSlashedEyeListPage"></i>
                </button>
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInput"
                  class="new-user-input3 form-control"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <button
                  @click="showPassword('passwordAgain')"
                  class="eyeButtonListPage"
                  id="showPasswordAgainButtonListPage"
                >
                  <i class="fa-solid fa-eye" id="pwAgainEyeListPage"></i>
                  <i
                    class="far fa-eye-slash"
                    id="pwAgainSlashedEyeListPage"
                  ></i>
                </button>
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="phoneNumInput2"
                  class="new-user-input3 form-control"
                  :placeholder="this.phoneNumPlaceholder"
                  @keydown="isInputEnter()"
                  maxlength="7"
                />
                <input
                  list="selected"
                  name="userSearch"
                  id="editPhoneNumSelectInput2"
                  class="new-user-input3 form-control"
                  autocomplete="off"
                  :placeholder="this.phoneselectedPlaceholder"
                  maxlength="2"
                  v-model="this.selectedNum"
                />

                <datalist id="selected">
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </datalist>
                <input
                  class="new-user-input3 form-control"
                  type="text"
                  id="plusPhoneList"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                  id="organizationInput1"
                >
                  <option hidden value="undefined">
                    {{ this.currentUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInput"
                  class="new-user-input3 form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />
                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input3 form-control"
                  name="role"
                  id="roleInput1"
                  disabled="disabled"
                  :placeholder="this.currentUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-success"
                    type="button"
                    id="sureButtonUserEdit"
                    @click="userEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit1"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- -------------------------------------USERS LIST MODAL-------------------------------- -->
    <div
      class="modal fade"
      id="usersModal"
      role="dialog"
      aria-labelledby="usersModal"
      aria-hidden="true"
    >
      <div class="large modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h4 class="modal-title">&nbsp; Users:</h4>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="allUsersList">
              <div class="container">
                <table
                  id="allUsersTableList"
                  class="allUsers table table-dark table-striped"
                >
                  <thead>
                    <tr>
                      <th>First Name:</th>
                      <th>Last Name:</th>

                      <th>Email:</th>
                      <th>Phone Number:</th>
                      <th>Organization:</th>
                      <th>Role:</th>
                      <th>Delete user:</th>
                      <th>Edit user:</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.userArray"
                      :key="item.userId"
                    >
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].first_name }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].last_name }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].email }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].phone_num }}
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        {{ this.userArray[index].organization }}
                      </td>

                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn"
                          data-bs-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false"
                          id="roleButton"
                        >
                          <i class="fas fa-user-alt" id="pIcon"></i>&ensp;
                          {{ this.userArray[index].role }}
                          <i class="fa-solid fa-caret-down" id="uIcon"></i>
                          &nbsp;
                        </button>
                        <div class="dropdown-menu calendarPageDropdownMenu">
                          <a
                            class="dropdown-item"
                            id="blockedContent"
                            @click="changeRole('developer', item)"
                            >Developer
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent3"
                            @click="changeRole('admin', item)"
                            >Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent4"
                            @click="changeRole('semiAdmin', item)"
                            >Semi-Admin
                          </a>
                          <a
                            class="dropdown-item"
                            id="blockedContent5"
                            @click="changeRole('user', item)"
                            >User
                          </a>
                        </div>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-danger"
                          id="deleteUserButtonList"
                          @click="deleteUser(item, index)"
                        >
                          Delete User
                        </button>
                      </td>
                      <td
                        v-show="
                          this.userArray[index].email != 'neededYes' &&
                          this.userArray[index].email != 'neededNo' &&
                          this.userArray[index].email != 'dev' &&
                          this.userArray[index].deleted != true
                        "
                      >
                        <button
                          type="button"
                          class="btn btn-secondary"
                          id="editUserButtonList"
                          data-bs-toggle="modal"
                          data-bs-target="#anotherUserEditModalList"
                          @click="selectUser(item)"
                          v-if="this.userArray[index].role != 'admin'"
                        >
                          Edit User
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-danger"
              data-bs-dismiss="modal"
              id="cancelButtonCreateCalendarPage"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- ----------------------ANOTHER USER EDIT MODAL------------------------- -->
    <div
      class="modal fade"
      id="anotherUserEditModalList"
      role="dialog"
      aria-labelledby="anotherUserEditModalList"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content anotherModal">
          <div class="modal-header">
            <h5 id="editTitle">&nbsp; User edit:</h5>
            <h5 class="modal-title" id="userEditModal"></h5>
            <button
              type="button"
              class="btn-close btn-close-white"
              data-bs-dismiss="modal"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row justify-content-center">
              <div class="col-auto">
                <label for="email">Email:&nbsp;</label>
                <input
                  v-model="editEmail"
                  type="text"
                  class="new-user-input3 form-control"
                  name="email"
                  id="anotherUserEmailInputList"
                  disabled="disabled"
                  autocomplete="off"
                  :placeholder="this.selectedUser.email"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="firstName">First Name:&nbsp;</label>
                <input
                  v-model="editFirstName"
                  type="text"
                  name="firstName"
                  id="anotherUserFirstNameInputList"
                  class="new-user-input3 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.first_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="lastName">Last Name:&nbsp;</label>
                <input
                  v-model="editLastName"
                  type="text"
                  name="lastName"
                  id="anotherUserLastNameInputList"
                  class="new-user-input3 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.last_name"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password:&nbsp;</label>
                <input
                  v-model="editPassword"
                  type="password"
                  name="password"
                  id="passwordInputAnotherList"
                  class="new-user-input3 form-control"
                  placeholder="Password"
                  autocomplete="off"
                  @keydown="isInputEnter"
                />
                <br />
                <br />
                <label for="password">Password again:&nbsp;</label>
                <input
                  v-model="editPasswordAgain"
                  type="password"
                  name="passwordAgain"
                  id="passwordAgainInputAnotherList"
                  class="new-user-input3 form-control"
                  autocomplete="off"
                  placeholder="Password"
                  @keydown="isInputEnter"
                />
                <br />
                <br />

                <label for="phoneNum">Phone Number:&nbsp;</label>
                <input
                  v-model="editPhoneNum"
                  type="text"
                  name="phoneNum"
                  id="anotherUserphoneNumInput2List"
                  class="new-user-input3 form-control"
                  autocomplete="off"
                  :placeholder="this.selectedUser.phone_num"
                  @keydown="isInputEnter"
                  maxlength="8"
                />
                <select
                  v-model="this.selectedNum"
                  class="new-user-input3 form-control"
                  id="phoneTwoList"
                >
                  &nbsp;
                  <option hidden value="undefined">
                    {{ phoneselectedPlaceholder }}
                  </option>
                  <option>20</option>
                  <option>30</option>
                  <option>31</option>
                  <option>50</option>
                  <option>70</option>
                  <option>96</option>
                </select>
                <input
                  class="new-user-input3 form-control"
                  type="text"
                  id="plusPhoneAnotherList"
                  :value="this.plusPhone"
                  disabled
                />&nbsp;

                <br />
                <br />

                <label for="type">Organization:&nbsp;</label>&nbsp;

                <select
                  v-model="this.selectedOrganization"
                  class="techDropdown"
                >
                  <option hidden value="undefined">
                    {{ this.selectedUser.organization }}
                  </option>
                  <option>DDC</option>
                  <option>JKK</option>
                  <option>AHJK</option>
                  <option>AK</option>
                  <option>EEKK</option>
                  <option>ESK</option>
                  <option>GIVK</option>
                  <option>KGK</option>
                  <option>MEK</option>
                  <option>MUK</option>
                  <option>Budapesti központ</option>
                  <option>ZalaZONE</option>
                  <option>None above</option>
                </select>
                <br v-show="this.selectedOrganization == 'None above'" />
                <br v-show="this.selectedOrganization == 'None above'" />
                <input
                  v-show="this.selectedOrganization == 'None above'"
                  v-model="editOrganization"
                  type="text"
                  name="organization"
                  id="organizationInputAnotherList"
                  class="new-user-input3 form-control"
                  placeholder="Organization"
                  @keydown="isCreateEnter()"
                />
                <br />
                <br />

                <label for="role">Role:&nbsp;</label>
                <input
                  type="text"
                  class="new-user-input3 form-control"
                  name="role"
                  id="roleInput2List"
                  disabled="disabled"
                  :placeholder="this.selectedUser.role"
                />
                <br />
                <br />
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    class="btn btn-success"
                    type="button"
                    id="sureButtonAnotherUserEdit"
                    data-bs-dismiss="modal"
                    @click="anotherUserEdit(item, index)"
                  >
                    Accept
                  </button>
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonUserEdit2"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <button
      type="button"
      class="btn btn-secondary"
      id="homeButtonList"
      @click="home()"
    >
      <i class="fa-solid fa-home"></i>
    </button>
    <button
      type="button"
      class="btn btn-secondary"
      id="basketButtonList"
      @click="basket()"
    >
      <i class="fa-solid fa-shopping-basket"></i>
    </button>
    <button
      data-toggle="tooltip"
      data-placement="right"
      title="User edit"
      id="userEditButtonList"
      class="btn btn-secondary"
      type="button"
      data-bs-toggle="modal"
      data-bs-target="#userEditModal"
    >
      <i class="fas fa-cogs"></i>
    </button>

    <button
      data-toggle="tooltip"
      data-placement="right"
      title="Logout"
      id="logoutButton2List"
      class="btn btn-secondary"
      type="button"
      @click="logout()"
    >
      <i class="fas fa-sign-out-alt"></i>
    </button>

    <a
      v-show="
        currentUser.role == 'admin' ||
        currentUser.role == 'semiAdmin' ||
        currentUser.role == 'developer'
      "
    >
      <button
        data-toggle="tooltip"
        data-placement="right"
        title="Users"
        id="usersButtonList"
        class="btn btn-secondary"
        type="button"
        data-bs-toggle="modal"
        data-bs-target="#usersModal"
      >
        <i class="fa-solid fa-address-card"></i>
      </button>
    </a>

    <div class="listViewContainer">
      <button class="btn" id="droneListShow" @click="droneActive()">
        Drones
      </button>
      <button id="batteryListShow" class="btn" @click="batteryActive()">
        Batteries
      </button>
      <button id="pilotListShow" class="btn" @click="pilotActive()">
        Pilots
      </button>
      <button id="userListShow" class="btn" @click="userActive()">Users</button>
      <button id="eventListShow" class="btn" @click="eventActive()">
        Events
      </button>
      <!-- --------------SEARCHBAR------------------------ -->
      <div class="searchBar">
        <div class="searchBarContainer">
          <input
            class="form-control searchBarInput"
            type="text"
            placeholder="🔍Search..."
            name="searchBar"
            id="searchFiled"
            @keyup="searchListPage()"
          />
        </div>
        <a
          id="eventDateInfo"
          href="#"
          title="Search for date like: 2022-09-02 "
          data-bs-toggle="popover"
          data-bs-trigger="hover"
          data-bs-content="Popover text"
          ><i class="fa-solid fa-circle-info"></i
        ></a>
      </div>
      <div class="allList">
        <!-- ------------DRONE LIST----------------------- -->
        <div class="allDroneList" v-show="this.droneListShow">
          <div class="card-group">
            <div
              v-for="(item, index) in this.droneArray"
              :key="item.id"
              :id="'droneListId' + index"
            >
              <div
                class="listPageCard card"
                v-show="this.droneArray[index].deleted != true"
              >
                <img
                  v-if="this.imgUuid[index] != undefined"
                  :src="this.apiUrl + '/drone_images/' + this.imgUuid[index]"
                  class="card-img-top cardimgtop"
                />
                <div class="card-body">
                  <h5 class="card-title">{{ this.droneArray[index].name }}</h5>
                  <p class="card-text">
                    Serial number: {{ this.droneArray[index].serial_num }}
                    <br />
                    Manufacturer: {{ this.droneArray[index].manufacturer }}
                  </p>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#droneMoreInfo"
                    style="color: #fff"
                    class="moreInfoButton btn btn-secondary"
                    :id="'droneMoreInfoButton' + item.lab_id"
                    @click="
                      selectDrone(item, index);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#droneHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    @click="getDroneHistory(item.lab_id)"
                    >History</a
                  >
                </div>
              </div>
            </div>
          </div>
          <br />
          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.droneArray"
              :key="item.id"
              :id="'droneListId' + index"
            >
              <div
                class="listPageCardNotActive card"
                v-show="this.droneArray[index].deleted == true"
              >
                <img
                  v-if="this.imgUuid[index] != undefined"
                  :src="this.apiUrl + '/drone_images/' + this.imgUuid[index]"
                  class="card-img-top cardimgtop"
                />
                <b class="inactiveText"> DELETED</b>
                <div class="card-body">
                  <h5 class="card-title">{{ this.droneArray[index].name }}</h5>
                  <p class="card-text">
                    Serial number: {{ this.droneArray[index].serial_num }}
                    <br />
                    Manufacturer: {{ this.droneArray[index].manufacturer }}
                  </p>
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#droneMoreInfo"
                    style="color: #fff"
                    class="moreInfoButton btn btn-secondary"
                    :id="'droneMoreInfoButton' + item.lab_id"
                    @click="
                      selectDrone(item, index);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    data-bs-toggle="modal"
                    data-bs-target="#droneHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    @click="getDroneHistory(item.lab_id)"
                    >History</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------------------DRONE HISTORY------------ -->
        <div
          class="modal fade"
          id="droneHistory"
          role="dialog"
          aria-labelledby="droneHistory"
          aria-hidden="true"
        >
          <div class="largeModalList modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2>Drone occupation history:</h2>
                <h5 class="modal-title" id="droneHistory"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                  id="closeButton"
                ></button>
              </div>
              <div class="modal-body">
                <!-- --------------SEARCHBAR------------------------ -->
                <div class="searchBarHistory">
                  <div class="searchBarContainerHistory">
                    <input
                      class="form-control searchBarInputHistory"
                      type="text"
                      placeholder="🔍Search..."
                      name="searchBar"
                      id="searchFiledDroneHistoryId"
                      @keyup="searchDroneHistory()"
                    />
                  </div>
                  <a
                    id="eventHistoryInfo"
                    href="#"
                    title="Search for date like: 2022-09-02 "
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </div>

                <br />
                <table
                  class="droneHistoryList table table-dark table-striped"
                  id="droneHistoryList"
                >
                  <thead>
                    <th>Event name:</th>
                    <th>Start date:</th>
                    <th>End date:</th>
                    <th>Pilot's licence ID:</th>
                    <th>User:</th>
                    <th>Description:</th>
                    <th>More Info:</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.droneHistory"
                      :key="item.event_id"
                      :id="'droneHistoryId' + index"
                    >
                      <td>
                        <!--  <button
                          v-show="this.droneHistoryEvents[index].reason"
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            droneHistoryEventSelect(
                              this.droneHistoryEvents[index]
                            )
                          "
                        > -->
                        {{ this.droneHistoryEvents[index].event_name }}
                        <!--  </button> -->
                        <!--   <p v-show="!this.droneHistoryEvents[index].reason">
                          {{ this.droneHistoryEvents[index].event_name }}
                        </p> -->
                      </td>
                      <td>
                        {{
                          new Date(
                            this.droneHistoryEvents[index].date_start
                          ).toLocaleString()
                        }}
                      </td>
                      <td>
                        {{
                          new Date(
                            this.droneHistoryEvents[index].date_end
                          ).toLocaleString()
                        }}
                      </td>
                      <td>{{ this.droneHistory[index].pilot_id }}</td>
                      <td>
                        {{
                          this.droneHistoryUsers[index].first_name +
                          " " +
                          this.droneHistoryUsers[index].last_name
                        }}
                      </td>
                      <td>
                        {{ this.droneHistoryEvents[index].reason }}
                      </td>
                      <td>
                        <button
                          v-show="this.droneHistoryEvents[index].reason"
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            droneHistoryEventSelect(
                              this.droneHistoryEvents[index]
                            )
                          "
                        >
                          Info
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer modalFooterList">
                <div class="footerButtons">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonCreateList"
                  >
                    <i class="fa-solid fa-circle-xmark"></i> Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!---------------------- DRONE HISTORY EXPORT MODAL--------------- -->
        <div class="modal" id="droneHistoryExportModal">
          <div class="modal-dialog">
            <div class="modal-content modalContentDarkList">
              <!-- Modal Header -->
              <div class="modal-header">
                <h4 class="modal-title">Export files</h4>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                Log files:
                <button type="button" class="btn btn-secondary">
                  Log file
                </button>
                <br /><br />
                Images after flight:
                <button type="button" class="btn btn-secondary">Images</button>
                <br /><br />
                PDF-s:
                <button type="button" class="btn btn-secondary">PDF</button>
                <br /><br />
              </div>

              <!-- Modal footer -->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- ---------Battery------------- -->
        <div class="allBatteryList" v-show="this.batteryListShow">
          <div class="card-group">
            <div
              v-for="(item, index) in this.batteryArray"
              :key="item.id"
              :id="'batteryListId' + index"
            >
              <div
                class="listPageCard card"
                v-show="this.batteryArray[index].deleted != true"
              >
                <img
                  v-if="this.imgUuidBattery[index] != undefined"
                  :src="
                    this.apiUrl +
                    '/battery_images/' +
                    this.imgUuidBattery[index]
                  "
                  class="card-img-top cardimgtop"
                />
                <div class="card-body">
                  <h5 class="card-title">
                    {{ this.batteryArray[index].name }}
                  </h5>
                  <p class="card-text">
                    Capacity: {{ this.batteryArray[index].capacity }} <br />
                    Cell number: {{ this.batteryArray[index].cell_num }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#batteryMoreInfo"
                    class="moreInfoButton btn btn-secondary"
                    :id="'batteryMoreInfoButton' + item.lab_id"
                    @click="
                      selectBattery(item, index);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#batteryHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    @click="getBatteryHistory(item.lab_id)"
                    >History</a
                  >
                </div>
              </div>
              <br />
            </div>
          </div>

          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.batteryArray"
              :key="item.id"
              :id="'batteryListId' + index"
            >
              <div
                class="listPageCardNotActive card"
                v-if="this.batteryArray[index].deleted == true"
              >
                <img
                  v-if="this.imgUuidBattery[index] != undefined"
                  :src="
                    this.apiUrl +
                    '/battery_images/' +
                    this.imgUuidBattery[index]
                  "
                  class="card-img-top cardimgtop"
                /><b class="inactiveText"> DELETED</b>
                <div class="card-body">
                  <h5 class="card-title">
                    {{ this.batteryArray[index].name }}
                  </h5>
                  <p class="card-text">
                    Capacity: {{ this.batteryArray[index].capacity }} <br />
                    Cell number: {{ this.batteryArray[index].cell_num }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#batteryMoreInfo"
                    class="moreInfoButton btn btn-secondary"
                    :id="'batteryMoreInfoButton' + item.lab_id"
                    @click="
                      selectBattery(item, index);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#batteryHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    @click="getBatteryHistory(item.lab_id)"
                    >History</a
                  >
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>
        <!-- --------------------------------------BATTERY HISTORY------------ -->
        <div
          class="modal fade"
          id="batteryHistory"
          role="dialog"
          aria-labelledby="batteryHistory"
          aria-hidden="true"
        >
          <div class="largeModalList modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2>Battery occupation history:</h2>
                <h5 class="modal-title" id="batteryHistory"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <!-- --------------SEARCHBAR------------------------ -->
                <div class="searchBarHistoryBattery">
                  <div class="searchBarContainerHistoryBattery">
                    <input
                      class="form-control searchBarInputHistoryBattery"
                      type="text"
                      placeholder="🔍Search..."
                      name="searchBar"
                      id="searchFiledBatteryHistoryId"
                      @keyup="searchBatteryHistory()"
                    />
                  </div>
                  <a
                    id="eventHistoryInfoBattery"
                    href="#"
                    title="Search for date like: 2022-09-02 "
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </div>

                <table
                  class="table table-dark table-striped"
                  id="batteryHistoryList"
                >
                  <thead>
                    <th>Event name:</th>
                    <th>Start date:</th>
                    <th>End date:</th>
                    <th>Pilot's licence ID:</th>
                    <th>User:</th>
                    <th>More Info:</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.batteryHistory"
                      :key="item.event_id"
                      :id="'batteryHistoryId' + index"
                    >
                      <td>
                        <!-- <button
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            batteryHistoryEventSelect(
                              this.batteryHistoryEvents[index]
                            )
                          "
                        > -->
                        {{ this.batteryHistoryEvents[index].event_name }}
                        <!-- </button> -->
                      </td>
                      <!-- <td v-else>
                        {{ this.batteryHistoryEvents[index].event_name }}
                      </td> -->
                      <td>
                        {{
                          new Date(
                            this.batteryHistoryEvents[index].date_start
                          ).toLocaleString()
                        }}
                      </td>
                      <td>
                        {{
                          new Date(
                            this.batteryHistoryEvents[index].date_end
                          ).toLocaleString()
                        }}
                      </td>
                      <td>{{ this.batteryHistory[index].pilot_id }}</td>
                      <td>
                        {{
                          this.batteryHistoryUsers[index].first_name +
                          " " +
                          this.batteryHistoryUsers[index].last_name
                        }}
                      </td>
                      <td>
                        <button
                          v-if="
                            !this.batteryHistoryEvents[
                              index
                            ].event_name.includes('Drone check after event:')
                          "
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            batteryHistoryEventSelect(
                              this.batteryHistoryEvents[index]
                            )
                          "
                        >
                          Info
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modalFooterList modal-footer">
                <div class="footerButtons">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonCreateList"
                  >
                    <i class="fa-solid fa-circle-xmark"></i> Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <br />
        <!-- ------------------------------------PILOT--------------------------------------------- -->
        <div class="allPilotList" v-show="this.pilotListShow">
          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.pilotArray"
              :key="item.id"
              :id="'pilotListId' + index"
            >
              <div
                class="listPageCard card"
                v-show="this.pilotArray[index].pilot_deleted != true"
              >
                <img
                  v-if="this.profil[index] != undefined"
                  :src="this.apiUrl + '/pilot_images/' + this.profil[index]"
                  class="card-img-top cardimgtop"
                />
                <div class="card-body">
                  <h5 class="card-title">
                    {{ this.pilotArray[index].first_name }}
                    {{ this.pilotArray[index].last_name }}
                  </h5>
                  <p class="card-text">
                    Licence level: {{ this.pilotArray[index].licence_level }}
                    <br />
                    Organization: {{ this.pilotArray[index].organization }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#pilotMoreInfo"
                    class="moreInfoButton btn btn-secondary pilotInfoButton"
                    :id="'pilotMoreInfoButton' + item.uuid"
                    @click="
                      selectPilot(item);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#pilotHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    @click="getPilotHistory(item.licence_id)"
                    >History</a
                  >
                </div>
              </div>
            </div>
          </div>
          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.pilotArray"
              :key="item.id"
              :id="'pilotListId' + index"
            >
              <div
                class="listPageCardNotActive card"
                v-show="this.pilotArray[index].pilot_deleted == true"
              >
                <img
                  v-if="this.profil[index] != undefined"
                  :src="this.apiUrl + '/pilot_images/' + this.profil[index]"
                  class="card-img-top cardimgtop"
                /><b class="inactiveText"> DELETED</b>
                <div class="card-body">
                  <h5 class="card-title">
                    {{ this.pilotArray[index].first_name }}
                    {{ this.pilotArray[index].last_name }}
                  </h5>
                  <p class="card-text">
                    Licence level: {{ this.pilotArray[index].licence_level }}
                    <br />
                    Organization: {{ this.pilotArray[index].organization }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#pilotMoreInfo"
                    class="moreInfoButton btn btn-secondary pilotInfoButton"
                    :id="'pilotMoreInfoButton' + item.uuid"
                    @click="
                      selectPilot(item);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                  <a
                    v-show="
                      currentUser.role == 'admin' ||
                      currentUser.role == 'semiAdmin' ||
                      currentUser.role == 'developer'
                    "
                    data-bs-toggle="modal"
                    data-bs-target="#pilotHistory"
                    class="btn btn-primary"
                    id="historyButton"
                    @click="getPilotHistory(item.licence_id)"
                    >History</a
                  >
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- --------------------------------------PILOT HISTORY------------ -->
        <div
          class="modal fade"
          id="pilotHistory"
          role="dialog"
          aria-labelledby="pilotHistory"
          aria-hidden="true"
        >
          <div class="largeModalList modal-dialog modal-lg" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h2>Pilot history:</h2>
                <h5 class="modal-title" id="pilotHistory"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <!-- --------------SEARCHBAR------------------------ -->
                <div class="searchBarHistoryPilot">
                  <div class="searchBarContainerHistoryPilot">
                    <input
                      class="form-control searchBarInputHistoryPilot"
                      type="text"
                      placeholder="🔍Search..."
                      name="searchBar"
                      id="searchFiledPilotHistoryId"
                      @keyup="searchPilotHistory()"
                    />
                  </div>
                  <a
                    id="eventHistoryInfoPilot"
                    href="#"
                    title="Search for date like: 2022-09-02 "
                    data-bs-toggle="popover"
                    data-bs-trigger="hover"
                    data-bs-content="Popover text"
                    ><i class="fa-solid fa-circle-info"></i
                  ></a>
                </div>

                <table
                  class="table table-dark table-striped"
                  id="pilotHistoryList"
                >
                  <thead>
                    <th>Event name:</th>
                    <th>Start date:</th>
                    <th>End date:</th>
                    <th>Pilot's licence ID:</th>
                    <th>User:</th>
                    <th>More Info:</th>
                  </thead>
                  <tbody>
                    <tr
                      v-for="(item, index) in this.pilotHistory"
                      :key="item.event_id"
                      :id="'pilotHistoryId' + index"
                    >
                      <td>
                        <!--  <button
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            pilotHistoryEventSelect(
                              this.pilotHistoryEvents[index]
                            )
                          "
                        > -->
                        {{ this.pilotHistoryEvents[index].event_name }}
                        <!--  </button> -->
                      </td>
                      <td>
                        {{
                          new Date(
                            this.pilotHistoryEvents[index].date_start
                          ).toLocaleString()
                        }}
                      </td>
                      <td>
                        {{
                          new Date(
                            this.pilotHistoryEvents[index].date_end
                          ).toLocaleString()
                        }}
                      </td>
                      <td>{{ this.pilotHistory[index].pilot_id }}</td>
                      <td>
                        {{
                          this.pilotHistoryUsers[index].first_name +
                          " " +
                          this.pilotHistoryUsers[index].last_name
                        }}
                      </td>
                      <td>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          @click="
                            pilotHistoryEventSelect(
                              this.pilotHistoryEvents[index]
                            )
                          "
                        >
                          Info
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div class="modal-footer">
                <div class="footerButtons">
                  <button
                    type="button"
                    data-bs-dismiss="modal"
                    class="btn btn-danger"
                    id="cancelButtonCreateList"
                  >
                    <i class="fa-solid fa-circle-xmark"></i> Cancel
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------USERLISTSHOW--------------------------------------------- -->
        <div class="allUserList" v-show="this.userListShow">
          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.userArray"
              :key="item.userId"
              :id="'userListId' + index"
            >
              <div
                class="card"
                id="userListCardId"
                v-show="
                  this.userArray[index].email != 'dev' &&
                  this.userArray[index].role != 'dev' &&
                  this.userArray[index].deleted != true
                "
              >
                <div class="card-body" id="cardBodyListAuto">
                  <h5 class="card-title">
                    {{ this.userArray[index].first_name }}
                    {{ this.userArray[index].last_name }}
                  </h5>
                  <p class="card-text">
                    Organization: {{ this.userArray[index].organization }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#userMoreInfo"
                    class="moreInfoButton btn btn-secondary userInfoButton"
                    :id="'userMoreInfoButton' + item.uuid"
                    @click="
                      selectUser(item);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                </div>
              </div>
              <br />
            </div>
          </div>

          <!-- 
      <div
        class="card-group"
        v-show="
          currentUser.role == 'admin' ||
          currentUser.role == 'semiAdmin' ||
          currentUser.role == 'developer'
        "
      >
        <div
          v-for="(item, index) in this.userArray"
          :key="item.userId"
          :id="'userListId' + index"
        >
          <div
            class="card"
            id="userListCardId"
          
            v-show="
              this.userArray[index].email != 'dev' &&
              this.userArray[index].role != 'dev' &&
              this.userArray[index].deleted == true
            "
          >
            <div class="card-body" id="cardBodyListAuto">
              <b class="inactiveTextUser"> DELETED</b>
              <h5 class="card-title">
                {{ this.userArray[index].first_name }}
                {{ this.userArray[index].last_name }}
              </h5>
              <p class="card-text">
                Organization: {{ this.userArray[index].organization }}
              </p>
              <a
                style="color: #fff"
                data-bs-toggle="modal"
                data-bs-target="#userMoreInfo"
                class="moreInfoButton btn btn-secondary userInfoButton"
                :id="'userMoreInfoButton' + item.uuid"
                @click="
                  selectUser(item);
                  this.editIndex = index;
                "
                >More information</a
              >&nbsp;
            </div>
          </div>
          <br />
        </div>
      </div> -->

          <div
            class="card-group"
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div
              v-for="(item, index) in this.userArray"
              :key="item.userId"
              :id="'userListId' + index"
            >
              <div
                class="card"
                id="userListCardId"
                v-show="
                  this.userArray[index].email != 'dev' &&
                  this.userArray[index].role != 'dev' &&
                  this.userArray[index].deleted == true
                "
              >
                <div class="card-body" id="cardBodyListAuto">
                  <b class="inactiveTextUser"> DELETED</b>
                  <h5 class="card-title">
                    {{ this.userArray[index].first_name }}
                    {{ this.userArray[index].last_name }}
                  </h5>
                  <p class="card-text">
                    Organization: {{ this.userArray[index].organization }}
                  </p>
                  <a
                    style="color: #fff"
                    data-bs-toggle="modal"
                    data-bs-target="#userMoreInfo"
                    class="moreInfoButton btn btn-secondary userInfoButton"
                    :id="'userMoreInfoButton' + item.uuid"
                    @click="
                      selectUser(item);
                      this.editIndex = index;
                    "
                    >More information</a
                  >&nbsp;
                </div>
              </div>
              <br />
            </div>
          </div>
        </div>

        <!---------------- USER MORE INFO MODAL ----------------------->
        <!-- The Modal -->
        <div class="modal" id="userMoreInfo">
          <div class="modal-dialog">
            <div class="modal-content listModal">
              <!-- Modal Header -->
              <div class="modal-header">
                <h4 class="modal-title">More information</h4>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <br />
                <table class="moreTable table table-bordered">
                  <tbody>
                    <tr>
                      <th>First name:</th>
                      <td>{{ this.selectedUser.first_name }}</td>
                    </tr>
                    <tr>
                      <th>Last name:</th>
                      <td>{{ this.selectedUser.last_name }}</td>
                    </tr>
                    <tr>
                      <th>Role:</th>
                      <td>{{ this.selectedUser.role }}</td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{{ this.selectedUser.email }}</td>
                    </tr>
                    <tr>
                      <th>Phone number:</th>
                      <td>{{ this.selectedUser.phone_num }}</td>
                    </tr>
                    <tr>
                      <th>Organization:</th>
                      <td>{{ this.selectedUser.organization }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Modal footer -->
              <div
                class="modal-footer"
                v-show="this.selectedUser.deleted != true"
              >
                <button
                  type="button"
                  id="userMoreInfoEditButton"
                  class="btn btn-success"
                  data-bs-toggle="modal"
                  data-bs-target="#anotherUserEditModalList"
                >
                  Edit
                </button>
                <button
                  type="button"
                  id="userMoreInfoCloseButton"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <!---------------- DRONE MODAL ----------------------->
        <div class="modal fade" id="droneMoreInfo">
          <div class="modal-dialog modal-lg">
            <div class="modal-content listModal">
              <!-- Modal Header -->
              <div class="modal-header">
                <!-- Carousel -->
                <div
                  id="dronePictures"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <!-- Indicators/dots -->
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#dronePictures"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                    <button
                      v-for="(item, index) in this.selectedDroneImages"
                      :key="item.lab_id"
                      :id="'carouselButtonId' + index"
                      type="button"
                      data-bs-target="#dronePictures"
                      :data-bs-slide-to="1 + index"
                    ></button>
                  </div>
                  <!-- The slideshow/carousel -->
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        v-if="this.selectedDroneImageNull != undefined"
                        :src="
                          this.apiUrl +
                          '/drone_images/' +
                          this.selectedDroneImageNull
                        "
                        alt="1"
                        class="d-block w-100"
                      />
                    </div>
                    <div
                      class="carousel-item"
                      v-for="(item, index) in this.selectedDroneImages"
                      :key="item.lab_id"
                      :id="'droneListId' + index"
                    >
                      <img
                        v-if="this.selectedDroneImages[index] != undefined"
                        :src="
                          this.apiUrl +
                          '/drone_images/' +
                          this.selectedDroneImages[index]
                        "
                        :alt="2 + index"
                        class="d-block w-100"
                      />
                    </div>
                  </div>

                  <!-- Left and right controls/icons -->
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#dronePictures"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#dronePictures"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <h5>{{ this.selectedDrone.name }}</h5>
                <br />
                <table class="moreTable table table-bordered">
                  <tbody>
                    <tr>
                      <th>Drone name:</th>
                      <td>{{ this.selectedDrone.name }}</td>
                    </tr>
                    <tr>
                      <th>Drone type:</th>
                      <td>{{ this.selectedDrone.type }}</td>
                    </tr>
                    <tr>
                      <th>Serial number/ID:</th>
                      <td>{{ this.selectedDrone.serial_num }}</td>
                    </tr>
                    <tr>
                      <th>Manufacturer:</th>
                      <td>{{ this.selectedDrone.manufacturer }}</td>
                    </tr>
                    <tr>
                      <th>Registration number:</th>
                      <td>{{ this.selectedDrone.registration_num }}</td>
                    </tr>
                    <tr>
                      <th>Start of insurance:</th>
                      <td>{{ this.selectedDrone.start }}</td>
                    </tr>
                    <tr>
                      <th>End of insurance:</th>
                      <td>{{ this.selectedDrone.end }}</td>
                    </tr>
                    <tr>
                      <th>Insurance number:</th>
                      <td>{{ this.selectedDrone.number }}</td>
                    </tr>

                    <tr>
                      <th>LAB ID:</th>
                      <td>{{ this.selectedDrone.lab_id }}</td>
                    </tr>
                    <tr>
                      <th>Drone Battery Type:</th>
                      <td>{{ this.batteryType1 }}</td>
                    </tr>
                    <tr>
                      <th>Drone Battery Connector:</th>
                      <td>{{ this.selectedDrone.connector }}</td>
                    </tr>
                    <tr>
                      <th>Required Batteries:</th>
                      <td>{{ this.selectedDrone.required_batteries }}</td>
                    </tr>
                    <tr>
                      <th>Location:</th>
                      <td>{{ this.selectedDrone.localization }}</td>
                    </tr>
                    <tr>
                      <th>Comment:</th>
                      <td>{{ this.selectedDrone.comment }}</td>
                    </tr>
                    <tr
                      v-show="
                        currentUser.role == 'admin' ||
                        currentUser.role == 'semiAdmin' ||
                        currentUser.role == 'developer'
                      "
                    >
                      <th>Bought:</th>
                      <td>{{ this.selectedDrone.bought }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Modal footer -->
              <div
                class="modal-footer modalFooterExtra"
                v-show="this.selectedDrone.deleted != true"
              >
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleModalEdit"
                  class="btn btn-primary"
                  id="listPageEditButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                >
                  <i class="fa-solid fa-pen-to-square"></i> Edit
                </button>
                <button
                  type="button"
                  @click="
                    deleteDroneListView(
                      this.selectedDrone,
                      this.selectedDrone.index
                    )
                  "
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="listPageDeleteButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                >
                  <i class="fa-solid fa-trash-can"></i> Delete
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!---------------- BATTERY MODAL ----------------------->
        <div class="modal" id="batteryMoreInfo">
          <div class="modal-dialog modal-lg">
            <div class="modal-content listModal">
              <!-- Modal Header -->
              <div class="modal-header">
                <!-- Carousel -->
                <div
                  id="batteryPictures"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <!-- Indicators/dots -->
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#batteryPictures"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                    <button
                      v-for="(item, index) in this.selectedBatteryImages"
                      :key="item.lab_id"
                      :id="'carouselButtonIdBattery' + index"
                      type="button"
                      data-bs-target="#batteryPictures"
                      :data-bs-slide-to="1 + index"
                    ></button>
                  </div>
                  <!-- The slideshow/carousel -->
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        v-if="this.selectedBatteryImageNull != undefined"
                        :src="
                          this.apiUrl +
                          '/battery_images/' +
                          this.selectedBatteryImageNull
                        "
                        alt="1"
                        class="d-block w-100"
                      />
                    </div>
                    <div
                      class="carousel-item"
                      v-for="(item, index) in this.selectedBatteryImages"
                      :key="item.lab_id"
                      :id="'batteryListId' + index"
                    >
                      <img
                        v-if="this.selectedBatteryImages[index] != undefined"
                        :src="
                          this.apiUrl +
                          '/battery_images/' +
                          this.selectedBatteryImages[index]
                        "
                        :alt="2 + index"
                        class="d-block w-100"
                      />
                    </div>
                  </div>

                  <!-- Left and right controls/icons -->
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#batteryPictures"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#batteryPictures"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <h5>{{ this.selectedBattery.name }}</h5>
                <br />
                <table class="moreTable table table-bordered">
                  <tbody>
                    <tr>
                      <th>Cellnumber: [S]</th>
                      <td>{{ this.selectedBattery.cell_num }}</td>
                    </tr>
                    <tr>
                      <th>Capacity: [mAh]</th>
                      <td>{{ this.selectedBattery.capacity }}</td>
                    </tr>
                    <tr>
                      <th>Manufacturer:</th>
                      <td>{{ this.selectedBattery.manufacturer }}</td>
                    </tr>
                    <tr>
                      <th>Type:</th>
                      <td>{{ this.selectedBattery.type }}</td>
                    </tr>
                    <tr>
                      <th>LAB ID:</th>
                      <td>{{ this.selectedBattery.lab_id }}</td>
                    </tr>
                    <tr>
                      <th>Connector:</th>
                      <td>{{ this.selectedBattery.connector }}</td>
                    </tr>

                    <tr>
                      <th>Name:</th>
                      <td>{{ this.selectedBattery.name }}</td>
                    </tr>
                    <tr>
                      <th>Technology:</th>
                      <td>{{ this.selectedBattery.technology }}</td>
                    </tr>

                    <tr>
                      <th>Location:</th>
                      <td>{{ this.selectedBattery.localization }}</td>
                    </tr>
                    <tr>
                      <th>C Value:</th>
                      <td>{{ this.selectedBattery.c_value }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Modal footer -->
              <div
                class="modal-footer modalFooterExtra"
                v-show="this.selectedBattery.deleted != true"
              >
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#exampleBatteryModalEdit"
                  class="btn btn-primary"
                  id="listPageEditButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                >
                  <i class="fa-solid fa-pen-to-square"></i> Edit
                </button>
                <button
                  type="button"
                  class="btn btn-danger"
                  id="listPageDeleteButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                  @click="
                    deleteBatteryListView(
                      this.selectedBattery,
                      this.selectedBattery.index
                    )
                  "
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-trash-can"></i> Delete
                </button>

                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <br />
        <br />
        <!---------------- PILOT MODAL ----------------------->
        <div class="modal" id="pilotMoreInfo">
          <div class="modal-dialog modal-lg">
            <div class="modal-content listModal">
              <!-- Modal Header -->
              <div class="modal-header">
                <!-- Carousel -->
                <div
                  id="pilotPictures"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <!-- Indicators/dots -->
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#pilotPictures"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                  </div>
                  <!-- The slideshow/carousel -->
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        v-if="this.profilImage != undefined"
                        :src="this.apiUrl + '/pilot_images/' + this.profilImage"
                        alt="1"
                        class="d-block w-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
              <!-- Modal body -->
              <div class="modal-body">
                <br />
                <table class="moreTable table table-bordered">
                  <tbody>
                    <tr>
                      <th>First name:</th>
                      <td>{{ this.selectedPilot.first_name }}</td>
                    </tr>
                    <tr>
                      <th>Last name:</th>
                      <td>{{ this.selectedPilot.last_name }}</td>
                    </tr>
                    <tr>
                      <th>Licence ID:</th>
                      <td>{{ this.selectedPilot.licence_id }}</td>
                    </tr>
                    <tr>
                      <th>Licence level:</th>
                      <td>{{ this.selectedPilot.licence_level }}</td>
                    </tr>
                    <tr>
                      <th>Email:</th>
                      <td>{{ this.selectedPilot.email }}</td>
                    </tr>
                    <tr>
                      <th>Phone number:</th>
                      <td>{{ this.selectedPilot.phone_num }}</td>
                    </tr>
                    <tr>
                      <th>Organization:</th>
                      <td>{{ this.selectedPilot.organization }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Modal footer -->
              <div
                class="modal-footer modalFooterExtra"
                v-show="this.selectedPilot.pilot_deleted != true"
              >
                <button
                  type="button"
                  data-bs-toggle="modal"
                  data-bs-target="#examplePilotModalEdit"
                  class="btn btn-primary"
                  id="listPageEditButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                >
                  <i class="fa-solid fa-pen-to-square"></i> Edit
                </button>
                <button
                  type="button"
                  @click="
                    deletePilotListView(
                      this.selectedPilot,
                      this.selectedPilot.index
                    )
                  "
                  data-bs-dismiss="modal"
                  class="btn btn-danger"
                  id="listPageDeleteButton"
                  v-show="
                    currentUser.role == 'admin' ||
                    currentUser.role == 'semiAdmin' ||
                    currentUser.role == 'developer'
                  "
                >
                  <i class="fa-solid fa-trash-can"></i> Delete
                </button>
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------------EVENT--------------------------------------------- -->
        <div class="allEventList" v-show="this.eventListShow">
          <div
            v-show="
              currentUser.role == 'admin' ||
              currentUser.role == 'semiAdmin' ||
              currentUser.role == 'developer'
            "
          >
            <div class="eventTable">
              <table class="eventListShowTable table" id="eventListShowTableId">
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Start</th>
                    <th>End</th>
                    <th>Reason</th>
                    <th>Deleted</th>
                    <th>More Info</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(item, index) in this.eventArrayCorrected"
                    :key="item.id"
                    :id="'eventListId' + index"
                  >
                    <td>{{ this.eventArrayCorrected[index].event_name }}</td>
                    <td>
                      {{
                        new Date(
                          this.eventArrayCorrected[index].date_start
                        ).toLocaleString()
                      }}
                    </td>
                    <td>
                      {{
                        new Date(
                          this.eventArrayCorrected[index].date_end
                        ).toLocaleString()
                      }}
                    </td>
                    <td>{{ this.eventArrayCorrected[index].reason }}</td>
                    <td>{{ this.eventArrayCorrected[index].deleted }}</td>
                    <td>
                      <button
                        data-bs-toggle="modal"
                        data-bs-target="#eventMoreInfo"
                        class="eventMoreInfoButton btn btn-secondary"
                        :id="'eventMoreInfoButton' + item.event_id"
                        @click="
                          selectEvent(item);
                          this.editIndex = index;
                        "
                      >
                        Info
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
          </div>
        </div>

        <!---------------- EVENT MODAL ----------------------->
        <div class="modal" id="eventMoreInfo">
          <div class="modal-dialog modal-lg" id="eventMoreInfoDialog">
            <div class="modal-content modalContExtra">
              <!-- Modal Header -->
              <div class="modal-header">
                More info
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <!-- Modal body -->
              <div class="modal-body" id="modalBodyBlack">
                <br />
                <table class="moreTable table table-bordered">
                  <tbody>
                    <tr>
                      <th class="thTableStyle">Event name:</th>
                      <td>{{ this.selectedEvent.event_name }}</td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Date start:</th>
                      <td>
                        {{
                          new Date(
                            this.selectedEvent.date_start
                          ).toLocaleString()
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Date end:</th>
                      <td>
                        {{
                          new Date(this.selectedEvent.date_end).toLocaleString()
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Reason:</th>
                      <td>{{ this.selectedEvent.reason }}</td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">User:</th>
                      <td>
                        <button
                          class="btn btn-secondary"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#userMoreInfo"
                          id="userButtonMoreInfo"
                          @click="
                            selectUserEvent(this.eventUser);
                            this.editIndex = index;
                          "
                        >
                          {{
                            this.eventUser.first_name +
                            " " +
                            this.eventUser.last_name
                          }}
                        </button>
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Pilots:</th>
                      <td>
                        <div class="card-group">
                          <div
                            v-for="(item, index) in this.clickedEventPilot"
                            :key="item.uuid"
                            class="moreInfoCard card"
                            style="color: #fff"
                            data-bs-toggle="modal"
                            data-bs-target="#pilotMoreInfo"
                            :id="'pilotMoreInfoButton' + item.uuid"
                            @click="
                              selectPilotEvent(item);
                              this.editIndex = index;
                            "
                          >
                            <img
                              v-if="this.actualPilotImages[index] != undefined"
                              :src="
                                this.apiUrl +
                                '/pilot_images/' +
                                this.actualPilotImages[index]
                              "
                              class="eventMoreInfoCardImage card-img-top"
                            />
                            <div class="card-body"></div>
                            <div class="blackTextCard">
                              {{ item.first_name }} {{ item.last_name }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Drones:</th>
                      <td>
                        <div class="card-group">
                          <div
                            v-for="(item, index) in this.clickedEventDrone"
                            :key="item.uuid"
                            class="moreInfoCard card"
                            data-bs-toggle="modal"
                            data-bs-target="#droneMoreInfo"
                            style="color: #fff"
                            :id="'droneMoreInfoButton' + item.lab_id"
                            @click="
                              selectDroneEvent(item, index);
                              this.editIndex = index;
                            "
                          >
                            <img
                              v-if="this.actualDroneImages[index] != undefined"
                              :src="
                                this.apiUrl +
                                '/drone_images/' +
                                this.actualDroneImages[index]
                              "
                              class="eventMoreInfoCardImage card-img-top"
                            />
                            <div class="card-body"></div>
                            <div class="blackTextCard">
                              {{ item.drone_lab_id }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Batteries:</th>
                      <td>
                        <div class="moreInfoCardGroup card-group">
                          <div
                            v-for="(item, index) in this.clickedEventBattery"
                            :key="item.uuid"
                            class="moreInfoCard card"
                            style="color: #fff"
                            data-bs-toggle="modal"
                            data-bs-target="#batteryMoreInfo"
                            :id="'batteryMoreInfoButton' + item.lab_id"
                            @click="
                              selectBatteryEvent(item, index);
                              this.editIndex = index;
                            "
                          >
                            <img
                              v-if="
                                this.actualBatteryImages[index] != undefined
                              "
                              :src="
                                this.apiUrl +
                                '/battery_images/' +
                                this.actualBatteryImages[index]
                              "
                              class="eventMoreInfoCardImage card-img-top"
                            />
                            <div class="card-body"></div>

                            <div class="blackTextCard">
                              {{ item.battery_lab_id }}
                            </div>
                          </div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Uploaded Images:</th>
                      <td>
                        <button
                          v-for="item in this.clickedEventDrone"
                          :key="item.uuid"
                          id="eventImagesButton1"
                          class="btn btn-secondary eventImagesButton"
                          type="button"
                          data-bs-toggle="modal"
                          data-bs-target="#eventDroneImagesModal"
                          @click="droneImagesDownload(item)"
                          v-show="item.after_check"
                        >
                          {{ item.drone_lab_id }} images open
                        </button>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Uploaded PDF-s:</th>
                      <td>
                        <button
                          v-for="item in this.clickedEventDrone"
                          :key="item.uuid"
                          id="eventImagesButton2"
                          class="btn btn-secondary eventImagesButton"
                          type="button"
                          v-show="item.after_check"
                          @click="openPdfWindow(item)"
                        >
                          {{ item.drone_lab_id }} PDF open
                        </button>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">Uploaded Logs:</th>
                      <td>
                        <button
                          v-for="item in this.clickedEventDrone"
                          :key="item.uuid"
                          id="eventImagesButton3"
                          class="btn btn-secondary eventImagesButton"
                          type="button"
                          v-show="item.after_check"
                          @click="downloadLogFiles(item)"
                        >
                          {{ item.drone_lab_id }} Logs download
                        </button>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">
                        Uploaded Acknowledgement receipt by transferring:
                      </th>
                      <td>
                        <button
                          v-if="this.selectedEvent.give_contract"
                          class="btn btn-secondary eventImagesButton"
                          type="button"
                          @click="
                            downloadHandoverContract(
                              this.selectedEvent.give_contract
                            )
                          "
                        >
                          Open PDF
                        </button>
                        &nbsp;
                      </td>
                    </tr>
                    <tr>
                      <th class="thTableStyle">
                        Uploaded Acknowledgement receipt by recipient:
                      </th>
                      <td>
                        <button
                          v-if="this.selectedEvent.get_contract"
                          class="btn btn-secondary eventImagesButton"
                          type="button"
                          @click="
                            downloadHandoverContract(
                              this.selectedEvent.get_contract
                            )
                          "
                        >
                          Open PDF
                        </button>
                        &nbsp;
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <!-- Modal footer -->
              <div class="modal-footer modalFooterExtra">
                <button
                  type="button"
                  class="btn btn-secondary"
                  data-bs-dismiss="modal"
                >
                  <i class="fa-solid fa-circle-xmark"></i> Close
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- ------------------------------DRONE IMAGE DOWNLOAD------------------------ -->
        <div class="modal" id="eventDroneImagesModal">
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <!-- Modal Header -->
              <div class="modal-header">
                <h4 class="modal-title">Images</h4>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>

              <!-- Modal body -->
              <div class="modal-body">
                <div
                  id="droneAfterFlightImages"
                  class="carousel slide"
                  data-bs-ride="carousel"
                >
                  <!-- Indicators/dots -->
                  <div class="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#droneAfterFlightImages"
                      data-bs-slide-to="0"
                      class="active"
                    ></button>
                    <button
                      v-for="(item, index) in this.droneImageDownload"
                      :key="item"
                      :id="'carouselButtonEventId' + index"
                      type="button"
                      data-bs-target="#droneAfterFlightImages"
                      :data-bs-slide-to="1 + index"
                    ></button>
                  </div>
                  <!-- The slideshow/carousel -->
                  <div class="carousel-inner">
                    <div class="carousel-item active">
                      <img
                        v-if="this.droneImageDownloadNull != undefined"
                        :src="
                          this.apiUrl +
                          '/drone_images_afterflight/' +
                          this.droneImageDownloadNull
                        "
                        alt="1"
                        class="d-block w-100"
                      />
                    </div>
                    <div
                      class="carousel-item"
                      v-for="(item, index) in this.droneImageDownload"
                      :key="item"
                      :id="'droneAfterFlightListId' + index"
                    >
                      <img
                        v-if="this.droneImageDownload[index] != undefined"
                        :src="
                          this.apiUrl +
                          '/drone_images_afterflight/' +
                          this.droneImageDownload[index]
                        "
                        :alt="2 + index"
                        class="d-block w-100"
                      />
                    </div>
                  </div>

                  <!-- Left and right controls/icons -->
                  <button
                    class="carousel-control-prev"
                    type="button"
                    data-bs-target="#droneAfterFlightImages"
                    data-bs-slide="prev"
                  >
                    <span class="carousel-control-prev-icon"></span>
                  </button>
                  <button
                    class="carousel-control-next"
                    type="button"
                    data-bs-target="#droneAfterFlightImages"
                    data-bs-slide="next"
                  >
                    <span class="carousel-control-next-icon"></span>
                  </button>
                </div>
              </div>

              <!-- Modal footer -->
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-danger"
                  data-bs-dismiss="modal"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>

        <!-- --------------------------------------EDIT DRONE MODAL---------------------------------------- -->
        <div
          class="modal"
          id="exampleModalEdit"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content listModal">
              <div class="modal-header">
                <h2 id="createDroneTitle">Edit drone</h2>
                <h5 class="modal-title" id="exampleModalLabel"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    Please fill the form if you want to Edit the Drone
                    <br />
                    <label for="name">Drone name:&nbsp;</label>
                    <input
                      v-model="editDroneName"
                      type="text"
                      name="Name"
                      id="editDroneName"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.name"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Drone type:&nbsp;</label>
                    <input
                      v-model="editDroneType"
                      type="text"
                      name="type"
                      id="editDroneType"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.type"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Serial number:&nbsp;</label>
                    <input
                      v-model="editDroneSerialNum"
                      type="text"
                      name="serialNumber"
                      id="editDroneSerialNum"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.serial_num"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Manufacturer:&nbsp;</label>
                    <input
                      v-model="editDroneManufacturer"
                      type="text"
                      name="manufacturer"
                      id="editDroneManufacturer"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.manufacturer"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Registration number:&nbsp;</label>
                    <input
                      v-model="editDroneRegNum"
                      type="text"
                      name="registrationNumber"
                      id="editDroneRegNum"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.registration_num"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Start of insurance:&nbsp;</label>
                    <input
                      v-model="editStartInsur"
                      type="date"
                      name="startInsurance"
                      id="editStartInsur"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.start"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">End of insurance:&nbsp;</label>
                    <input
                      v-model="editEndInsur"
                      type="date"
                      name="endInsurance"
                      id="editEndInsur"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.end"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Insurance Number:&nbsp;</label>
                    <input
                      v-model="editDroneInsurNum"
                      type="text"
                      name="insuranceNumber"
                      id="editDroneInsurNum"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.number"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />

                    <label for="type">Drone LAB ID:&nbsp;</label>
                    <input
                      v-model="editDroneLabId"
                      type="text"
                      name="comment"
                      id="editDroneLabId"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.lab_id"
                      @keydown="isCreateEnter"
                      disabled="disabled"
                    />
                    <br />
                    <br />

                    <label for="type">Drone Battery Type:&nbsp;</label>
                    <input
                      v-model="editDroneBatteryType"
                      type="text"
                      name="comment"
                      id="editDroneBatteryType"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.batteryType"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />

                    <label for="type">Drone Battery Connector:&nbsp;</label>
                    <input
                      v-model="editDroneBatteryConnector"
                      type="text"
                      name="comment"
                      id="editDroneBatteryConnector"
                      class="edit-drone form-control"
                      :placeholder="this.selectedDrone.connector"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />

                    <label for="type">Required Batteries:</label>
                    <input
                      v-model="editReqBatteries"
                      type="number"
                      name="comment"
                      id="editReqBatteries"
                      class="listPage-input form-control"
                      :placeholder="this.selectedDrone.required_batteries"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />

                    <label for="type">Location:&nbsp;</label>
                    <input
                      v-model="editDroneLocal"
                      type="text"
                      name="comment"
                      id="editDroneLocal"
                      class="listPage-input form-control"
                      :placeholder="this.selectedDrone.localization"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />

                    <label for="type">Comment:&nbsp;</label>
                    <input
                      v-model="editDroneComment"
                      type="text"
                      name="comment"
                      id="editDroneComment"
                      class="listPage-input form-control"
                      :placeholder="this.selectedDrone.comment"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="type">Bought:&nbsp;</label>
                    <select
                      v-model="this.selectedDrone.bought"
                      class="custom-select techDropdown"
                    >
                      <option value="undefined" hidden selected>
                        {{ this.selectedDrone.bought }}
                      </option>
                      <option>true</option>
                      <option>false</option>
                    </select>

                    <br />
                    <br />
                    <label class="form-label" for="customFile"
                      >Upload more images:</label
                    >
                    <input
                      type="file"
                      class="listPage-input form-control"
                      id="droneImageUpload"
                    />
                    <!-- image lesz ide -->
                    <br />
                    <br />
                  </div>
                  <div class="modal-footer">
                    <div class="footerButtons">
                      <button
                        type="button"
                        id="sureButtonCreateList"
                        class="btn btn-success"
                        @click="editDroneListView()"
                        data-bs-target="#exampleModalEdit"
                        data-bs-dismiss="modal"
                      >
                        <i class="fa-solid fa-floppy-disk"></i> Save</button
                      >&nbsp;
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="btn btn-danger"
                        id="cancelButtonCreateList"
                      >
                        <i class="fa-solid fa-circle-xmark"></i> Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- --------------------------------------EDIT BATTERY MODAL---------------------------------------- -->
        <div
          class="modal"
          id="exampleBatteryModalEdit"
          role="dialog"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content listModal">
              <div class="modal-header">
                <h2 id="createBatteryTitle">Edit battery</h2>
                <h5 class="modal-title" id="exampleBatteryModalLabel"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    Please fill the form if you want to Edit the Battery
                    <br />

                    <label for="cellnumber">Cellnumber: [S]&nbsp;</label>
                    <input
                      v-model="editBatteryCellNum"
                      type="text"
                      name="cellnumber"
                      id="editBatteryCellNum"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.cell_num"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="capacity">Capacity: [mAh]&nbsp;</label>
                    <input
                      v-model="editBatteryCapacity"
                      type="text"
                      name="capacity"
                      id="editBatteryCapacity"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.capacity"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryManufacturer">Manufacturer:&nbsp;</label>
                    <input
                      v-model="editBatteryManufacturer"
                      type="text"
                      name="batteryManufacturer"
                      id="editBatteryManufacturer"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.manufacturer"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryType">Type:&nbsp;</label>
                    <input
                      v-model="editBatteryType"
                      type="text"
                      name="batteryType"
                      id="editBatteryType"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.type"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryLabId">LAB ID:&nbsp;</label>
                    <input
                      v-model="editBatteryLabId"
                      type="text"
                      name="batteryLabId"
                      id="editBatteryLabId"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.lab_id"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="BatteryConnector">Connector:&nbsp;</label>
                    <input
                      v-model="editBatteryConnector"
                      type="text"
                      name="batteryConnector"
                      id="editBatteryConnector"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.connector"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryCValue">Name:&nbsp;</label>
                    <input
                      v-model="editBatteryName"
                      type="text"
                      name="batteryName"
                      id="editBatteryName"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.name"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryType">Technology:&nbsp;</label>
                    <select
                      v-model="editBatteryTechnology"
                      class="techDropdown"
                    >
                      <option :value="undefined" hidden>
                        {{ this.selectedBattery.technology }}
                      </option>
                      <option>Li-Po</option>
                      <option>Li-Ion</option>
                    </select>
                    <br />
                    <br />
                    <label for="batteryLocal">Localization:&nbsp;</label>
                    <input
                      v-model="editBatteryLocal"
                      type="text"
                      name="batteryLocal"
                      id="editBatteryLocal"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.localization"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="batteryLocal">C Value:&nbsp;</label>
                    <input
                      v-model="editBatteryCValue"
                      type="text"
                      name="batteryCValue"
                      id="editBatteryCValue"
                      class="listPage-input form-control"
                      :placeholder="this.selectedBattery.c_value"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label class="form-label" for="customFile"
                      >Upload more images:</label
                    >
                    <input
                      type="file"
                      class="listPage-input form-control"
                      id="batteryImageUpload"
                    />
                    <!-- image lesz ide -->
                    <br />
                    <br />
                    <!-- image lesz ide -->
                  </div>
                  <div class="modal-footer">
                    <div class="footerButtons">
                      <button
                        type="button"
                        id="sureButtonCreateListBattery"
                        class="btn btn-success"
                        data-bs-dismiss="modal"
                        @click="editBatteryListView()"
                      >
                        <i class="fa-solid fa-floppy-disk"></i> Save</button
                      >&nbsp;
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="btn btn-danger"
                        id="cancelButtonCreateListBattery"
                      >
                        <i class="fa-solid fa-circle-xmark"></i> Cancel
                      </button>
                      <br />
                      <br />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- --------------------------------------EDIT PILOT MODAL---------------------------------------- -->
        <div
          class="modal"
          id="examplePilotModalEdit"
          role="dialog"
          aria-labelledby="examplePilotModalEdit"
          aria-hidden="true"
        >
          <div class="modal-dialog" role="document">
            <div class="modal-content listModal">
              <div class="modal-header">
                <h2 id="createPilotTitle">Edit pilot</h2>
                <h5 class="modal-title" id="examplePilotModalEdit"></h5>
                <button
                  type="button"
                  class="btn-close btn-close-white"
                  data-bs-dismiss="modal"
                ></button>
              </div>
              <div class="modal-body">
                <div class="row justify-content-center">
                  <div class="col-auto">
                    Please fill the form if you want to Edit the Pilot
                    <br />
                    <br />
                    <label for="licenceId">Licence ID:&nbsp;</label>
                    <input
                      v-model="editLicenceId"
                      type="text"
                      name="licenceId"
                      id="editLicenceId"
                      class="listPage-input form-control"
                      :placeholder="this.selectedPilot.licence_id"
                      @keydown="isCreateEnter"
                    />
                    <br />
                    <br />
                    <label for="licenceLevel">Licence Level:&nbsp;</label>
                    <select v-model="editLicenceLevel" class="techDropdown">
                      <option value="undefined" hidden>
                        {{ this.selectedPilot.licence_level }}
                      </option>
                      <option>A1/A3</option>
                      <option>A2</option>
                    </select>
                    <br />
                    <br />
                  </div>
                  <div class="modal-footer">
                    <div class="footerButtons">
                      <button
                        type="button"
                        id="sureButtonCreateListPilot"
                        class="btn btn-success"
                        data-bs-dismiss="modal"
                        @click="editPilotListView()"
                      >
                        <i class="fa-solid fa-floppy-disk"></i> Save</button
                      >&nbsp;
                      <button
                        type="button"
                        data-bs-dismiss="modal"
                        class="btn btn-danger"
                        id="cancelButtonCreateListPilot"
                      >
                        <i class="fa-solid fa-circle-xmark"></i> Cancel
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dataProvider from "../utils/dataProvider.js";
const dp = dataProvider();

export default {
  methods: {
    //------------------------------------------Download Handover CONTRACT------------------------
    async downloadHandoverContract(uuid) {
      console.log(uuid);
      window.open(this.apiUrl + "/downloadHandoverContract/" + uuid);
    },
    //------------------------------------------DOWNLOAD LOG FILES--------------------------------
    async downloadLogFiles(item) {
      let logs = await dp.getDroneLogsLabId(item.event_id, item.drone_lab_id);
      for (let index = 0; index < logs.length; index++) {
        window.open(this.apiUrl + "/drone_log/" + logs[index].uuid);
      }
    },
    //-------------------------------------------OPEN PDF WINDOW-----------------------------------
    async openPdfWindow(item) {
      let pdf = await dp.getDronePdfLabId(item.event_id, item.drone_lab_id);
      window.open(this.apiUrl + "/drone_pdf/" + pdf.uuid);
    },
    //----------------------------------------SHOW PASSOWRDS-------------------------------------
    showPassword(key) {
      switch (key) {
        case "password":
          if (document.getElementById("passwordInput").type == "password") {
            document.getElementById("passwordInput").type = "text";
            document.getElementById("pwEyeListPage").style.display = "none";
            document.getElementById("pwSlashedEyeListPage").style.display =
              "block";
          } else if (document.getElementById("passwordInput").type == "text") {
            document.getElementById("passwordInput").type = "password";
            document.getElementById("pwEyeListPage").style.display = "block";
            document.getElementById("pwSlashedEyeListPage").style.display =
              "none";
          }
          break;
        case "passwordAgain":
          if (
            document.getElementById("passwordAgainInput").type == "password"
          ) {
            document.getElementById("passwordAgainInput").type = "text";
            document.getElementById("pwAgainEyeListPage").style.display =
              "none";
            document.getElementById("pwAgainSlashedEyeListPage").style.display =
              "block";
          } else if (
            document.getElementById("passwordAgainInput").type == "text"
          ) {
            document.getElementById("passwordAgainInput").type = "password";
            document.getElementById("pwAgainEyeListPage").style.display =
              "block";
            document.getElementById("pwAgainSlashedEyeListPage").style.display =
              "none";
          }
          break;
        default:
          break;
      }
    },

    /* -----------------------DRONE HISTORY--------------------------------- */
    droneHistoryEventSelect(item) {
      document.getElementById("eventMoreInfoButton" + item.event_id).click();
    },
    batteryHistoryEventSelect(item) {
      document.getElementById("eventMoreInfoButton" + item.event_id).click();
    },
    pilotHistoryEventSelect(item) {
      document.getElementById("eventMoreInfoButton" + item.event_id).click();
    },

    /* ---------------TESZT------------------- */

    ///--------------------------------------------EDIT ANOTHER USER----------------------------------------------
    async anotherUserEdit() {
      this.editEmail = document.getElementById(
        "anotherUserEmailInputList"
      ).placeholder;

      this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;

      this.editPhoneNum =
        this.editPhoneNum ||
        document.getElementById("anotherUserphoneNumInput2List").placeholder;

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("anotherUserFirstNameInputList").placeholder;

      this.editLastName =
        this.editLastName ||
        document.getElementById("anotherUserLastNameInputList").placeholder;

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;

      if (this.editPassword == this.editPasswordAgain) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editFirstName,
          this.editLastName,
          this.editOrganization
        );
        this.userArray = await dp.getUserList();
        this.phoneNumPlaceholder = await this.selectedUserEdit.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder =
          await this.selectedUserEdit.phone_num.slice(3, 5);
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;

        alert("Editing was successfull!");
      } else alert("Passwords doesn't match!");
    },
    /* -------------DRONE SEARCH------------- */

    async searchListPage() {
      if (this.droneListShow) {
        let searchDrone = document
          .getElementById("searchFiled")
          .value.toLowerCase();

        for (let i = 0; i < this.droneArray.length; i++) {
          if (
            !this.droneArray[i].name.toLowerCase().includes(searchDrone) &&
            !this.droneArray[i].serial_num
              .toLowerCase()
              .includes(searchDrone) &&
            !this.droneArray[i].manufacturer.toLowerCase().includes(searchDrone)
          ) {
            document.getElementById("droneListId" + i).style.display = "none";
          } else {
            document.getElementById("droneListId" + i).style.display = "block";
          }
        }
      }
      if (this.batteryListShow) {
        let searchBattery = document
          .getElementById("searchFiled")
          .value.toLowerCase();

        for (let i = 0; i < this.batteryArray.length; i++) {
          if (
            !this.batteryArray[i].name.toLowerCase().includes(searchBattery) &&
            !this.batteryArray[i].capacity
              .toLowerCase()
              .includes(searchBattery) &&
            !this.batteryArray[i].cell_num.toLowerCase().includes(searchBattery)
          ) {
            document.getElementById("batteryListId" + i).style.display = "none";
          } else {
            document.getElementById("batteryListId" + i).style.display =
              "block";
          }
        }
      }
      if (this.pilotListShow) {
        let searchPilot = document
          .getElementById("searchFiled")
          .value.toLowerCase();

        for (let i = 0; i < this.pilotArray.length; i++) {
          if (
            !this.pilotArray[i].first_name
              .toLowerCase()
              .includes(searchPilot) &&
            !this.pilotArray[i].last_name.toLowerCase().includes(searchPilot) &&
            !this.pilotArray[i].licence_level
              .toLowerCase()
              .includes(searchPilot) &&
            !this.pilotArray[i].organization.toLowerCase().includes(searchPilot)
          ) {
            document.getElementById("pilotListId" + i).style.display = "none";
          } else {
            document.getElementById("pilotListId" + i).style.display = "block";
          }
        }
      }
      if (this.userListShow) {
        let searchUser = document
          .getElementById("searchFiled")
          .value.toLowerCase();

        for (let i = 0; i < this.userArray.length; i++) {
          if (
            !this.userArray[i].first_name.toLowerCase().includes(searchUser) &&
            !this.userArray[i].last_name.toLowerCase().includes(searchUser) &&
            !this.userArray[i].role.toLowerCase().includes(searchUser) &&
            !this.userArray[i].organization.toLowerCase().includes(searchUser)
          ) {
            document.getElementById("userListId" + i).style.display = "none";
          } else {
            document.getElementById("userListId" + i).style.display = "block";
          }
        }
      }
      if (this.eventListShow) {
        let searchEvent = document
          .getElementById("searchFiled")
          .value.toLowerCase();

        for (let i = 0; i < this.eventArrayCorrected.length; i++) {
          if (
            !this.eventArrayCorrected[i].event_name
              .toLowerCase()
              .includes(searchEvent) &&
            !this.eventArrayCorrected[i].reason
              .toLowerCase()
              .includes(searchEvent) &&
            !this.eventArrayCorrected[i].date_start
              .toLocaleString()
              .toLowerCase()
              .includes(searchEvent) &&
            !this.eventArrayCorrected[i].date_end
              .toLocaleString()
              .toLowerCase()
              .includes(searchEvent) &&
            !this.eventArrayCorrected[i].deleted
              .toString()
              .toLowerCase()
              .includes(searchEvent)
          ) {
            document.getElementById("eventListId" + i).style.display = "none";
          } else {
            document.getElementById("eventListId" + i).style.display = "";
          }
        }
      }
    },

    /* --------------------------DRONE HISTORY SEARCH --------------------- */
    async searchDroneHistory() {
      let searchDroneHistories = document
        .getElementById("searchFiledDroneHistoryId")
        .value.toLowerCase();

      for (let i = 0; i < this.droneHistory.length; i++) {
        if (
          !this.droneHistoryEvents[i].event_name
            .toLowerCase()
            .includes(searchDroneHistories) &&
          !this.droneHistory[i].pilot_id
            .toLowerCase()
            .includes(searchDroneHistories) &&
          !this.droneHistoryUsers[i].first_name
            .toLowerCase()
            .includes(searchDroneHistories) &&
          !this.droneHistoryUsers[i].last_name
            .toLowerCase()
            .includes(searchDroneHistories) &&
          !this.droneHistoryEvents[i].date_start
            .toLowerCase()
            .includes(searchDroneHistories) &&
          !this.droneHistoryEvents[i].date_end
            .toLowerCase()
            .includes(searchDroneHistories)
        ) {
          document.getElementById("droneHistoryId" + i).style.display = "none";
        } else {
          document.getElementById("droneHistoryId" + i).style.display = "";
        }
      }
    },
    /* --------------------------BATTERY HISTORY SEARCH --------------------- */
    async searchBatteryHistory() {
      let searchBatteryHistories = document
        .getElementById("searchFiledBatteryHistoryId")
        .value.toLowerCase();

      for (let i = 0; i < this.batteryHistory.length; i++) {
        if (
          !this.batteryHistoryEvents[i].event_name
            .toLowerCase()
            .includes(searchBatteryHistories) &&
          !this.batteryHistory[i].pilot_id
            .toLowerCase()
            .includes(searchBatteryHistories) &&
          !this.batteryHistoryUsers[i].first_name
            .toLowerCase()
            .includes(searchBatteryHistories) &&
          !this.batteryHistoryUsers[i].last_name
            .toLowerCase()
            .includes(searchBatteryHistories) &&
          !this.batteryHistoryEvents[i].date_start
            .toLowerCase()
            .includes(searchBatteryHistories) &&
          !this.batteryHistoryEvents[i].date_end
            .toLowerCase()
            .includes(searchBatteryHistories)
        ) {
          document.getElementById("batteryHistoryId" + i).style.display =
            "none";
        } else {
          document.getElementById("batteryHistoryId" + i).style.display = "";
        }
      }
    },
    /* --------------------------PILOT HISTORY SEARCH --------------------- */
    async searchPilotHistory() {
      let searchPilotHistories = document
        .getElementById("searchFiledPilotHistoryId")
        .value.toLowerCase();

      for (let i = 0; i < this.pilotHistory.length; i++) {
        if (
          !this.pilotHistoryEvents[i].event_name
            .toLowerCase()
            .includes(searchPilotHistories) &&
          !this.pilotHistory[i].pilot_id
            .toLowerCase()
            .includes(searchPilotHistories) &&
          !this.pilotHistoryUsers[i].first_name
            .toLowerCase()
            .includes(searchPilotHistories) &&
          !this.pilotHistoryUsers[i].last_name
            .toLowerCase()
            .includes(searchPilotHistories) &&
          !this.pilotHistoryEvents[i].date_start
            .toLowerCase()
            .includes(searchPilotHistories) &&
          !this.pilotHistoryEvents[i].date_end
            .toLowerCase()
            .includes(searchPilotHistories)
        ) {
          document.getElementById("pilotHistoryId" + i).style.display = "none";
        } else {
          document.getElementById("pilotHistoryId" + i).style.display = "";
        }
      }
    },

    /*--------------------------------PILOT HISTORY-----------------------------*/
    async getPilotHistory(pilotId) {
      let pilotHistory = await dp.getReservedDronesByPilotId(pilotId);
      let pilotHistoryEvents = [];
      let pilotHistoryUsers = [];

      for (const events of pilotHistory) {
        pilotHistoryEvents.push(await dp.getEventById(events.event_id));
        pilotHistoryUsers.push(await dp.getUserByUuid(events.user_uuid));
      }
      this.pilotHistoryEvents = [...pilotHistoryEvents];
      this.pilotHistory = [...pilotHistory];
      this.pilotHistoryUsers = [...pilotHistoryUsers];
    },

    /*--------------------------------DRONE HISTORY-----------------------------*/
    async getDroneHistory(droneLabId) {
      let droneHistory = await dp.getReservedDronesByLabId(droneLabId);
      let droneHistoryEvents = [];
      let droneHistoryUsers = [];
      for (const events of droneHistory) {
        droneHistoryEvents.push(await dp.getEventById(events.event_id));
        droneHistoryUsers.push(await dp.getUserByUuid(events.user_uuid));
      }

      this.droneHistoryEvents = [...droneHistoryEvents];
      this.droneHistory = [...droneHistory];
      this.droneHistoryUsers = [...droneHistoryUsers];

      document.getElementById("searchFiledDroneHistoryId").value = "";
    },
    /*--------------------------------BATTERY HISTORY-----------------------------*/
    async getBatteryHistory(batteryLabId) {
      let batteryHistory = await dp.getReservedBatteryByLabId(batteryLabId);
      let batteryHistoryEvents = [];
      let batteryHistoryUsers = [];
      for (const events of batteryHistory) {
        batteryHistoryEvents.push(await dp.getEventById(events.event_id));
        batteryHistoryUsers.push(await dp.getUserByUuid(events.user_uuid));
      }
      this.batteryHistoryEvents = [...batteryHistoryEvents];
      this.batteryHistory = [...batteryHistory];
      this.batteryHistoryUsers = [...batteryHistoryUsers];
    },

    /* -----------------------------LOGOUT------------------------------- */
    logout() {
      localStorage.removeItem("userEmail");
      localStorage.removeItem("JWT");
      localStorage.removeItem("username");

      this.$router.push("/login");
    },
    async home() {
      this.$router.push("/");
    },
    async basket() {
      this.$router.push("/basket");
    },
    /* ------------------------BUTTON STATUS UPDATE FUNCTIONS------------------------- */
    buttonPush() {
      this.buttonPushed = !this.buttonPushed;
    },
    droneActive() {
      this.droneListShow = true;
      this.batteryListShow = false;
      this.pilotListShow = false;
      this.userListShow = false;
      this.eventListShow = false;
      document.getElementById("droneListShow").style =
        "background-color: #6d6875";
      document.getElementById("batteryListShow").style =
        "background-color: #212529";
      document.getElementById("pilotListShow").style =
        "background-color: #212529";
      document.getElementById("userListShow").style =
        "background-color: #212529";
      document.getElementById("eventListShow").style =
        "background-color: #212529";
      document.getElementById("searchFiled").value = "";
    },
    batteryActive() {
      this.batteryListShow = true;
      this.droneListShow = false;
      this.pilotListShow = false;
      this.userListShow = false;
      this.eventListShow = false;
      document.getElementById("droneListShow").style =
        "background-color: #212529";
      document.getElementById("batteryListShow").style =
        "background-color: #6d6875";
      document.getElementById("pilotListShow").style =
        "background-color: #212529";
      document.getElementById("userListShow").style =
        "background-color: #212529";
      document.getElementById("eventListShow").style =
        "background-color: #212529";
      document.getElementById("searchFiled").value = "";
    },
    pilotActive() {
      this.pilotListShow = true;
      this.batteryListShow = false;
      this.droneListShow = false;
      this.userListShow = false;
      this.eventListShow = false;
      document.getElementById("droneListShow").style =
        "background-color: #212529";
      document.getElementById("batteryListShow").style =
        "background-color: #212529";
      document.getElementById("pilotListShow").style =
        "background-color: #6d6875";
      document.getElementById("userListShow").style =
        "background-color: #212529";
      document.getElementById("eventListShow").style =
        "background-color: #212529";
      document.getElementById("searchFiled").value = "";
    },
    userActive() {
      this.userListShow = true;
      this.batteryListShow = false;
      this.droneListShow = false;
      this.pilotListShow = false;
      this.eventListShow = false;
      document.getElementById("droneListShow").style =
        "background-color: #212529";
      document.getElementById("batteryListShow").style =
        "background-color: #212529";
      document.getElementById("userListShow").style =
        "background-color: #6d6875";
      document.getElementById("pilotListShow").style =
        "background-color: #212529";

      document.getElementById("eventListShow").style =
        "background-color: #212529";
      document.getElementById("searchFiled").value = "";
    },
    eventActive() {
      this.eventListShow = true;
      this.batteryListShow = false;
      this.droneListShow = false;
      this.userListShow = false;
      this.pilotListShow = false;
      document.getElementById("droneListShow").style =
        "background-color: #212529";
      document.getElementById("batteryListShow").style =
        "background-color: #212529";
      document.getElementById("pilotListShow").style =
        "background-color: #212529";
      document.getElementById("userListShow").style =
        "background-color: #212529";
      document.getElementById("eventListShow").style =
        "background-color: #6d6875";
      document.getElementById("searchFiled").value = "";
    },

    /* -------------------------------------DELETE BATTERY----------------------------------- */

    async deleteBatteryListView(item, index) {
      if (confirm("Are you sure you want to DELETE this battery?")) {
        let batteryDelete = await dp.getReservedBatteryByLabId(item.lab_id);
        if (batteryDelete.length >= 1) {
          console.log("Soft delete");
          if (confirm("This battery is connected to an event!")) {
            await dp.softDeleteBatteryImagesByLabId(item);
            await dp.softDeleteBatteryById(item);
          }
        } else if (batteryDelete.length == 0) {
          console.log("Hard delete");
          await dp.deleteBatteryImagesByLabId(item);
          await dp.deleteBatteryById(item);
          this.batteryArray.splice(index, 1);
        }
      }
      // await dp.getReservedBatteryByLabId(item.lab_id);
      this.batteryArray = await dp.getBatteryList();
    },
    /* ---------------------LIST VIEW FUNCTIONS ------------------------------ */
    /* ----------------------------------------SELECT PILOT--------------------------------- */
    async selectPilot(item, index) {
      this.selectedPilot = item;
      this.selectedPilot.index = index;
      let pilotImages = await dp.getPilotImagesByLicenceId(item.licence_id);

      if (pilotImages.uuid != undefined) {
        this.profilImage = pilotImages.uuid;
      }
    },

    async selectPilotEvent(item) {
      let pilot = await dp.getPilotByUserUuid(item.uuid);
      let pilotImages = await dp.getPilotImagesByLicenceId(pilot.licence_id);

      console.log(pilot);
      if (pilotImages.uuid != undefined) {
        this.profilImage = pilotImages.uuid;
      }
      this.selectedPilot = {
        first_name: item.first_name,
        last_name: item.last_name,
        licence_id: pilot.licence_id,
        licence_level: pilot.licence_level,
        email: item.email,
        phone_num: item.phone_num,
        organization: item.organization,
        pilot_deleted: pilot.deleted,
      };
      console.log(this.selectedPilot);
      console.log(pilot);
    },
    selectUser(item) {
      this.selectedUser = item;
    },
    selectUserEvent(item) {
      this.selectedUser = item;
    },
    /* -------------------------------------DELETE DRONE----------------------------------- */
    async deleteDroneListView(item, index) {
      if (confirm("Are you sure you want to DELETE this drone?")) {
        let droneDelete = await dp.getReservedDronesByLabId(item.lab_id);

        if (droneDelete.length >= 1) {
          console.log("Soft delete");
          if (confirm("This drone is connected to an event!")) {
            await dp.softDeleteDroneImagesByLabId(item);
            await dp.softDeleteDroneInsuranceById(item);
            await dp.softDeleteDroneBatteryById(item);
            await dp.softDeleteDroneById(item);
          }
        } else if (droneDelete.length == 0) {
          console.log("Hard delete");
          await dp.deleteDroneImagesByLabId(item);
          await dp.deleteDroneInsuranceById(item);
          await dp.deleteDroneBatteryById(item);
          await dp.deleteDroneById(item);
          this.droneArray.splice(index, 1);
        }
      }
      this.droneArray = await dp.getDroneList()//await dp.getDroneNotDeleted();
    },
    /////////////////////////------------------------EDIT DRONE-----------------------------------------------------
    async editDroneListView() {
      this.selectedDrone.old_lab_id = this.selectedDrone.lab_id;

      this.droneArray[this.editIndex].name =
        this.editDroneName ||
        document.getElementById("editDroneName").placeholder;

      this.droneArray[this.editIndex].type =
        this.editDroneType ||
        document.getElementById("editDroneType").placeholder;

      this.droneArray[this.editIndex].serial_num =
        this.editDroneSerialNum ||
        document.getElementById("editDroneSerialNum").placeholder;

      this.droneArray[this.editIndex].manufacturer =
        this.editDroneManufacturer ||
        document.getElementById("editDroneManufacturer").placeholder;

      this.droneArray[this.editIndex].registration_num =
        this.editDroneRegNum ||
        document.getElementById("editDroneRegNum").placeholder;

      this.droneArray[this.editIndex].start =
        this.editStartInsur ||
        document.getElementById("editStartInsur").placeholder;

      this.droneArray[this.editIndex].end =
        this.editEndInsur ||
        document.getElementById("editEndInsur").placeholder;

      this.droneArray[this.editIndex].number =
        this.editDroneInsurNum ||
        document.getElementById("editDroneInsurNum").placeholder;

      this.droneArray[this.editIndex].lab_id =
        this.editDroneLabId ||
        document.getElementById("editDroneLabId").placeholder;

      this.droneArray[this.editIndex].batteryType =
        this.editDroneBatteryType ||
        document.getElementById("editDroneBatteryType").placeholder;

      this.droneArray[this.editIndex].connector =
        this.editDroneBatteryConnector ||
        document.getElementById("editDroneBatteryConnector").placeholder;

      this.droneArray[this.editIndex].required_batteries =
        this.editReqBatteries ||
        document.getElementById("editReqBatteries").placeholder;

      this.droneArray[this.editIndex].localization =
        this.editDroneLocal ||
        document.getElementById("editDroneLocal").placeholder;

      this.droneArray[this.editIndex].comment =
        this.editDroneComment ||
        document.getElementById("editDroneComment").placeholder;

      this.droneArray[this.editIndex].bought = this.selectedDrone.bought;
      await dp.editDrone(
        this.droneArray[this.editIndex].name,
        this.droneArray[this.editIndex].type,
        this.droneArray[this.editIndex].serial_num,
        this.droneArray[this.editIndex].manufacturer,
        this.droneArray[this.editIndex].lab_id,
        this.droneArray[this.editIndex].comment,
        this.droneArray[this.editIndex].bought,
        this.droneArray[this.editIndex].registration_num,
        this.droneArray[this.editIndex].localization,
        this.selectedDrone.old_lab_id
      );
      await dp.updateDroneBatteryById(
        this.droneArray[this.editIndex].batteryType,
        this.droneArray[this.editIndex].connector,
        this.droneArray[this.editIndex].required_batteries,
        this.droneArray[this.editIndex].lab_id
      );
      await dp.updateDroneInsuranceById(
        this.droneArray[this.editIndex].start,
        this.droneArray[this.editIndex].end,
        this.droneArray[this.editIndex].number,
        this.droneArray[this.editIndex].lab_id
      );
      this.selectedDrone = {
        name: this.droneArray[this.editIndex].name,
        type: this.droneArray[this.editIndex].type,
        serial_num: this.droneArray[this.editIndex].serial_num,
        manufacturer: this.droneArray[this.editIndex].manufacturer,
        registration_num: this.droneArray[this.editIndex].registration_num,
        start: this.droneArray[this.editIndex].start,
        end: this.droneArray[this.editIndex].end,
        number: this.droneArray[this.editIndex].number,
        lab_id: this.droneArray[this.editIndex].lab_id,
        batteryType: this.droneArray[this.editIndex].batteryType,
        connector: this.droneArray[this.editIndex].connector,
        required_batteries: this.droneArray[this.editIndex].required_batteries,
        localization: this.droneArray[this.editIndex].localization,
        comment: this.droneArray[this.editIndex].comment,
        bought: this.droneArray[this.editIndex].bought,
      };
    },
    /* ------------EDIT BATTERY------------------------------ */
    editBatteryListView() {
      this.selectedBattery.old_lab_id = this.selectedBattery.lab_id;
      this.batteryArray[this.editIndex].cell_num =
        this.editBatteryCellNum ||
        document.getElementById("editBatteryCellNum").placeholder;

      this.batteryArray[this.editIndex].capacity =
        this.editBatteryCapacity ||
        document.getElementById("editBatteryCapacity").placeholder;

      this.batteryArray[this.editIndex].manufacturer =
        this.editBatteryManufacturer ||
        document.getElementById("editBatteryManufacturer").placeholder;

      this.batteryArray[this.editIndex].type =
        this.editBatteryType ||
        document.getElementById("editBatteryType").placeholder;

      this.batteryArray[this.editIndex].lab_id =
        this.editBatteryLabId ||
        document.getElementById("editBatteryLabId").placeholder;

      this.batteryArray[this.editIndex].connector =
        this.editBatteryConnector ||
        document.getElementById("editBatteryConnector").placeholder;

      this.batteryArray[this.editIndex].name =
        this.editBatteryName ||
        document.getElementById("editBatteryName").placeholder;

      this.batteryArray[this.editIndex].technology =
        this.editBatteryTechnology || this.selectedBattery.technology;

      this.batteryArray[this.editIndex].localization =
        this.editBatteryLocal ||
        document.getElementById("editBatteryLocal").placeholder;

      this.batteryArray[this.editIndex].c_value =
        this.editBatteryCValue ||
        document.getElementById("editBatteryCValue").placeholder;
      dp.editBattery(
        this.batteryArray[this.editIndex].cell_num,
        this.batteryArray[this.editIndex].capacity,
        this.batteryArray[this.editIndex].manufacturer,
        this.batteryArray[this.editIndex].type,
        this.batteryArray[this.editIndex].lab_id,
        this.batteryArray[this.editIndex].connector,
        this.batteryArray[this.editIndex].name,
        this.batteryArray[this.editIndex].technology,
        this.batteryArray[this.editIndex].localization,
        this.batteryArray[this.editIndex].c_value,
        this.selectedBattery.old_lab_id
      );
    },
    /* -----------------------------DELETE PILOT---------------------------- */
    async deletePilotListView(item, index) {
      this.pilotArray = await dp.getPilotListWithUserData();
      if (confirm("Are you sure you want to DELETE this pilot?")) {
        let pilotDelete = await dp.getReservedDronesByPilotId(item.licence_id);
        if (pilotDelete.length >= 1) {
          console.log("Soft delete");
          if (confirm("This pilot is connected to an event!")) {
            await dp.softDeletePilotImagesByLicenceId(item);
            await dp.softDeletePilotById(item);
          }
        } else if (pilotDelete.length == 0) {
          console.log("Hard delete");

          await dp.deletePilotImagesByLicenceId(item);
          await dp.deletePilotById(item);
          this.pilotArray.splice(index, 1);
        }
      }
      this.pilotArray = await dp.getPilotListWithUserData();
    },

    /* ------------------------------USERS---------------------------------- */
    async deleteUser(item, index) {
      if (confirm("Are you sure you want to DELETE this user?")) {
        this.pilotUser = await dp.getPilotByUserUuid(item.uuid);
        this.pilotDelete = [];
        this.userDelete = [];
        if (this.pilotUser) {
          this.pilotDelete = await dp.getReservedDronesByPilotId(
            this.pilotUser.licence_id
          );
          this.userDelete = await dp.getReservedDronesByUserId(
            this.pilotUser.user_uuid
          );
        } else {
          this.userDelete = await dp.getReservedDronesByUserId(item.uuid);
          this.pilotDelete = [];
        }

        console.log(
          this.pilotUser,
          this.userDelete.length,
          this.pilotDelete.length,
          index
        );
        if (this.pilotUser) {
          //User has pilot added to it
          if (
            (this.userDelete.length >= 1 && this.pilotDelete.length >= 1) ||
            (this.userDelete.length >= 1 && this.pilotDelete.length == 0) ||
            (this.userDelete.length == 0 && this.pilotDelete.length >= 1)
          ) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              await dp.softDeletePilotImagesByLicenceId(this.pilotUser);
              await dp.softDeletePilotById(this.pilotUser);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deletePilotImagesByLicenceId(this.pilotUser);
            await dp.deletePilotById(this.pilotUser);
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        } else {
          //User dont have pilot added to it
          if (this.userDelete.length >= 1) {
            // 1 1
            if (confirm("This user and pilot is connected to an event!")) {
              await dp.softDeleteUserByEmail(item);
              this.userArray.splice(index, 1);
            }
          } else {
            // 0 0
            await dp.deleteUserByEmail(item);
            this.userArray.splice(index, 1);
          }
        }
      }
      this.userArray = await dp.getUserList();
    },

    ///--------------------------------------------EDIT USER----------------------------------------------
    async userEdit() {
      let editSelectPhoneNum = false;
      let editPhoneNum = false;
      let editPassword = false;
      let editPasswordAgain = false;

      let validatePassword = (password) => {
        return String(password).match(/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/);
      };
      let validatePhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{6,7})$/);
      };
      let validateSelectedPhoneNum = (phoneNum) => {
        return String(phoneNum).match(/^([0-9]{2})$/);
      };

      if (
        validatePassword(this.editPassword) ||
        this.editPassword == undefined
      ) {
        document.getElementById("passwordInput").style.border =
          "medium solid #3f9140";
        editPassword = true;
      } else {
        document.getElementById("passwordInput").style.border =
          "medium solid #c9021d";
        alert(
          "Password must contain: \n -at least 8 characters \n -at least 1 number \n -at least 1 lowercase character\n -at least 1 uppercase character"
        );
      }

      if (this.editPassword == this.editPasswordAgain) {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #3f9140";
        editPasswordAgain = true;
      } else {
        document.getElementById("passwordAgainInput").style.border =
          "medium solid #c9021d";
        alert("Passwords doesn't match");
      }

      this.editEmail = document.getElementById("emailInput").placeholder;
      document.getElementById("emailInput").style.border =
        "medium solid #3f9140";

      if (
        validateSelectedPhoneNum(this.selectedNum) ||
        this.selectedNum == undefined
      ) {
        this.selectedNum = this.selectedNum || this.phoneselectedPlaceholder;
        document.getElementById("editPhoneNumSelectInput2").style.border =
          "medium solid #3f9140";
        editSelectPhoneNum = true;
      } else {
        document.getElementById("editPhoneNumSelectInput2").style.border =
          "medium solid #c9021d";
      }
      if (
        validatePhoneNum(this.editPhoneNum) ||
        this.editPhoneNum == undefined
      ) {
        this.editPhoneNum =
          this.editPhoneNum ||
          document.getElementById("phoneNumInput2").placeholder;
        document.getElementById("phoneNumInput2").style.border =
          "medium solid #3f9140";
        editPhoneNum = true;
      } else {
        document.getElementById("phoneNumInput2").style.border =
          "medium solid #c9021d";
      }

      this.editFirstName =
        this.editFirstName ||
        document.getElementById("firstNameInput").placeholder;

      document.getElementById("firstNameInput").style.border =
        "medium solid #3f9140";

      this.editLastName =
        this.editLastName ||
        document.getElementById("lastNameInput").placeholder;
      document.getElementById("lastNameInput").style.border =
        "medium solid #3f9140";

      this.editOrganization =
        this.editOrganization || this.selectedOrganization;
      document.getElementById("organizationInput1").style.border =
        "medium solid #3f9140";
      document.getElementById("organizationInput").style.border =
        "medium solid #3f9140";

      document.getElementById("plusPhoneList").style.border =
        "medium solid #3f9140";
      document.getElementById("roleInput2List").style.border =
        "medium solid #3f9140";

      if (
        editPassword &&
        editPasswordAgain &&
        editSelectPhoneNum &&
        editPhoneNum
      ) {
        await dp.editUserByEmail(
          this.editPassword,
          this.editEmail,
          this.plusPhone + this.selectedNum + this.editPhoneNum,
          this.editRole,
          this.editLastName,
          this.editFirstName,
          this.editOrganization
        );
        this.userArray = await dp.getUserList();
        this.currentUser = await dp.getUserByEmail(this.editEmail);
        this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(
          5,
          12
        );
        this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
          3,
          5
        );
        this.editUsername = undefined;
        this.editPassword = undefined;
        this.selectedNum = undefined;
        this.editPasswordAgain = undefined;
        this.editEmail = undefined;
        this.editPhoneNum = undefined;
        this.editFirstName = undefined;
        this.editLastName = undefined;
        this.editOrganization = undefined;
        document.getElementById("cancelButtonUserEdit2").click();
        alert("Editing was successfull!");
      }
    },

    async changeRole(role, item) {
      await dp.editUserByEmail(
        undefined,
        item.email,
        item.phoneNum,
        role,
        item.lastName,
        item.firstName,
        item.organization
      );
      this.userArray = await dp.getUserList();
    },
    /* -----------------------------EDIT PILOT---------------------------- */

    async editPilotListView() {
      this.pilotArray[this.editIndex].licence_id =
        this.editLicenceId ||
        document.getElementById("editLicenceId").placeholder;

      this.pilotArray[this.editIndex].licence_level =
        this.editLicenceLevel || this.selectedPilot.licence_level;
      await dp.editPilotByUserUuid(
        this.pilotArray[this.editIndex].licence_id,
        this.pilotArray[this.editIndex].licence_level,
        this.pilotArray[this.editIndex].user_uuid
      );
      this.selectedPilot = this.pilotArray[this.editIndex];
    },
    /* -----------------SELECT BATTERY------------------------------ */
    async selectBattery(item, index) {
      this.selectedBattery = item;
      this.selectedBattery.index = index;
      let batteryImages = await dp.getBatteryImagesByLabId(item.lab_id);
      this.selectedBatteryImageNull = undefined;
      this.selectedBatteryImages = [];

      for (let index = 0; index < batteryImages.length; index++) {
        let element = batteryImages[index];
        this.selectedBatteryImages.push(element.uuid);
      }

      this.selectedBatteryImageNull = this.selectedBatteryImages.shift();
    },
    /* -----------------SELECT BATTERY EVENT------------------------------ */
    async selectBatteryEvent(item, index) {
      let battery = await dp.getBatteryById(item.battery_lab_id);
      let batteryImages = await dp.getBatteryImagesByLabId(item.battery_lab_id);
      this.selectedBatteryImageNull = undefined;
      this.selectedBatteryImages = [];

      for (let index = 0; index < batteryImages.length; index++) {
        let element = batteryImages[index];
        this.selectedBatteryImages.push(element.uuid);
      }
      this.selectedBatteryImageNull = this.selectedBatteryImages.shift();

      this.selectedBattery = {
        cell_num: battery.cell_num,
        capacity: battery.capacity,
        manufacturer: battery.manufacturer,
        type: battery.type,
        lab_id: battery.lab_id,
        connector: battery.connector,
        name: battery.name,
        technology: battery.technology,
        localization: battery.localization,
        c_value: battery.c_value,
        index: index,
        old_lab_id: battery.old_lab_id,
        deleted: battery.deleted,
      };
    },
    /* ------------DDRONE SELECT------------------------------ */
    async selectDrone(item, index) {
      let droneBattery = await dp.getDroneBatteryById(item.lab_id);
      let droneInsurance = await dp.getDroneInsuranceById(item.lab_id);
      let droneImages = await dp.getDroneImagesByLabId(item.lab_id);
      this.selectedDroneImageNull = undefined;
      this.selectedDroneImages = [];
      for (let index = 0; index < droneImages.length; index++) {
        let element = droneImages[index];
        this.selectedDroneImages.push(element.uuid);
      }
      this.selectedDroneImageNull = this.selectedDroneImages.shift();

      this.selectedDrone = {
        name: item.name,
        type: item.type,
        serial_num: item.serial_num,
        manufacturer: item.manufacturer,
        registration_num: item.registration_num,
        start: droneInsurance.start,
        end: droneInsurance.end,
        number: droneInsurance.number,
        lab_id: item.lab_id,
        batteryType: droneBattery.type,
        connector: droneBattery.connector,
        required_batteries: droneBattery.required_batteries,
        localization: item.localization,
        comment: item.comment,
        bought: item.bought,
        deleted: item.deleted,
        index: index,
      };
      if (droneBattery) {
        if (droneBattery.type.length > 0) {
          this.batteryTypeLength = JSON.parse(
            JSON.stringify(droneBattery.type).length
          );

          this.batteryType1 = JSON.stringify(droneBattery.type)
            .slice(2, this.batteryTypeLength - 2)
            .replace(/["]/g, " ");
        }
      }
    },
    /* ------------DDRONE EVENT SELECT------------------------------ */
    async selectDroneEvent(item, index) {
      let drone = await dp.getDroneById(item.drone_lab_id);
      let droneBattery = await dp.getDroneBatteryById(item.drone_lab_id);
      let droneInsurance = await dp.getDroneInsuranceById(item.drone_lab_id);
      let droneImages = await dp.getDroneImagesByLabId(item.drone_lab_id);
      this.selectedDroneImageNull = undefined;
      this.selectedDroneImages = [];

      for (let index = 0; index < droneImages.length; index++) {
        let element = droneImages[index];
        this.selectedDroneImages.push(element.uuid);
      }
      this.selectedDroneImageNull = this.selectedDroneImages.shift();

      this.selectedDrone = {
        name: drone.name,
        type: drone.type,
        serial_num: drone.serial_num,
        manufacturer: drone.manufacturer,
        registration_num: drone.registration_num,
        start: droneInsurance.start,
        end: droneInsurance.end,
        number: droneInsurance.number,
        lab_id: drone.lab_id,
        batteryType: droneBattery.type,
        connector: droneBattery.connector,
        required_batteries: droneBattery.required_batteries,
        localization: drone.localization,
        comment: drone.comment,
        bought: drone.bought,
        deleted: drone.deleted,
        index: index,
      };
      console.log(this.selectedDrone);
      if (droneBattery) {
        if (droneBattery.type.length > 0) {
          this.batteryTypeLength = JSON.parse(
            JSON.stringify(droneBattery.type).length
          );

          this.batteryType1 = JSON.stringify(droneBattery.type)
            .slice(2, this.batteryTypeLength - 2)
            .replace(/["]/g, " ");
        }
      }
    },
    /* ----------------------------------------SELECT EVENT--------------------------------- */

    async selectEvent(item) {
      this.selectedEvent = item;
      let reserved_drones = await dp.getReservedDronesByEventIdFree(
        item.event_id
      );
      this.eventUser = await dp.getUserByUuid(reserved_drones[0].user_uuid);
      console.log(reserved_drones[0].user_uuid);
      console.log(reserved_drones[0]);
      let reservedBatteries = await dp.getReservedBatteriesByEventIdFree(
        item.event_id
      );
      console.log(this.selectedEvent.role);
      this.clickedEventPilot = [];
      let drones = [];
      let pilots = [];
      for (let element of reserved_drones) {
        drones.push(element.drone_lab_id);
        for (
          let pilotIndex = 0;
          pilotIndex < element.pilot_id.length;
          pilotIndex++
        ) {
          pilots.push(element.pilot_id[pilotIndex]);
        }
      }
      let pilotIndex = 0;
      let droneIndex = 0;
      let batteryIndex = 0;
      this.actualBatteryImages = [];
      this.actualPilotImages = [];
      this.actualDroneImages = [];

      for await (let pilot of pilots) {
        pilots[pilotIndex] = await dp.getPilotById(pilot);
        let images = await dp.getPilotImagesByLicenceId(
          pilots[pilotIndex].licence_id
        );
        this.actualPilotImages.push(images.uuid);
        pilots[pilotIndex] = await dp.getUserByUuid(
          pilots[pilotIndex].user_uuid
        );
        if (
          pilots[pilotIndex].uuid != "7481d999-5781-4ac0-9166-53d71fbcd333" &&
          pilots[pilotIndex].uuid != "de9a7413-a497-47ad-a621-de3397a6e348"
        ) {
          this.clickedEventPilot.push(pilots[pilotIndex]);
        }
        pilotIndex++;
      }
      this.clickedEventDrone = [];
      for await (let droneLabId of drones) {
        let droneImages = await dp.getDroneImagesByLabId(droneLabId);
        this.actualDroneImages.push(droneImages[0].uuid);
        this.clickedEventDrone[droneIndex] = reserved_drones[droneIndex];
        droneIndex++;
      }
      let batteries = [];
      for (let element of reservedBatteries) {
        batteries.push(element.battery_lab_id);
      }
      this.clickedEventBattery = [];
      for await (let batteryLabId of batteries) {
        let batteryImages = await dp.getBatteryImagesByLabId(batteryLabId);
        this.actualBatteryImages.push(batteryImages[0].uuid);
        this.clickedEventBattery[batteryIndex] =
          reservedBatteries[batteryIndex];
        batteryIndex++;
      }
    },

    /* -------------------CARD PUSH----------------------- */

    async droneImagesDownload(item) {
      this.droneImageDownloadFull = await dp.getAfterImagesByEventAndLabId(
        item.drone_lab_id,
        item.event_id
      );
      this.droneImageDownload = [];
      this.droneImageDownloadFull.forEach((element) => {
        this.droneImageDownload.push(element.uuid);
      });
      this.droneImageDownloadNull = this.droneImageDownload.shift();
      /* console.log(this.droneImageDownload, this.droneImageDownloadNull); */
    },
  },
  async mounted() {
    console.log("Mounted");

    this.droneImageArray = await dp.getDroneImages();
    this.droneArray = await dp.getDroneList();

    for (const element of this.droneArray) {
      let images = await dp.getDroneImagesByLabId(element.lab_id);

      if (images.length != 0) {
        this.imgUuid.push(images[0].uuid);
      }
    }

    this.pilotArray = await dp.getPilotListWithUserData();
    this.pilotImageArray = await dp.getPilotImages();
    for (const element of this.pilotArray) {
      let images = await dp.getPilotImagesByLicenceId(element.licence_id);
      this.profil.push(images.uuid);
    }

    this.batteryArray = await dp.getBatteryList();

    for (const element of this.batteryArray) {
      let imagesBattery = await dp.getBatteryImagesByLabId(element.lab_id);
      if (imagesBattery.length != 0) {
        this.imgUuidBattery.push(imagesBattery[0].uuid);
      }
    }

    this.eventArray = await dp.getEventList();

    for (const element of this.eventArray) {
      if (element.reason) {
        this.eventArrayCorrected.push(element);
      }
    }

    this.requests = await dp.getRequestList();
    this.userArray = await dp.getUserList();
    this.currentUser = await dp.getUserByEmail(
      localStorage.getItem("userEmail")
    );
    this.phoneNumPlaceholder = await this.currentUser.phone_num.slice(5, 12);
    this.phoneselectedPlaceholder = await this.currentUser.phone_num.slice(
      3,
      5
    );
  },

  data() {
    return {
      apiUrl: process.env.VUE_APP_API_URL || "http://localhost:5000",
      selectedBatteryImageNull: undefined,
      selectedDroneImageNull: undefined,
      selectedPilotImageNull: undefined,
      droneImageDownloadFull: [],
      droneImageDownload: [],
      droneImageDownloadNull: undefined,
      actualPilotImages: [],
      actualBatteryImages: [],
      actualDroneImages: [],
      profil: [],
      eventArrayCorrected: [],
      droneEventArrayCorrected: [],
      profilImage: undefined,
      selectedPilotImages: [],
      selectedDroneImages: [],
      selectedBatteryImages: [],
      imgUuid: [],
      imgUuidBattery: [],

      //------------------------------------DRONE HISTORY-----------------------------------
      droneHistory: [
        {
          event_id: undefined,
          pilot_id: undefined,
          user_uuid: undefined,
          drone_lab_id: undefined,
          id: undefined,
        },
      ],
      droneHistoryEvents: [
        {
          date_end: undefined,
          date_start: undefined,
          evaluation: undefined,
          event_id: undefined,
          event_name: undefined,
          reason: undefined,
          request_status: undefined,
          user_uuid: undefined,
        },
      ],
      droneHistoryUsers: [
        {
          first_name: undefined,
          last_name: undefined,
          organization: undefined,
          email: undefined,
          phone_num: undefined,
          role: undefined,
          uuid: undefined,
        },
      ],
      //----------------------------EDIT ANOTHER USER--------------

      selectedUserEdit: {
        email: undefined,
        first_name: undefined,
        last_name: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      //------------------------------------BATTERY HISTORY-----------------------------------
      batteryHistory: [
        {
          event_id: undefined,
          pilot_id: undefined,
          user_uuid: undefined,
          battery_lab_id: undefined,
          id: undefined,
        },
      ],
      batteryHistoryEvents: [
        {
          date_end: undefined,
          date_start: undefined,
          evaluation: undefined,
          event_id: undefined,
          event_name: "undefined",
          reason: undefined,
          request_status: undefined,
          user_uuid: undefined,
        },
      ],
      batteryHistoryUsers: [
        {
          first_name: undefined,
          last_name: undefined,
          organization: undefined,
          email: undefined,
          phone_num: undefined,
          role: undefined,
          uuid: undefined,
        },
      ],
      //------------------------------------PILOT HISTORY-----------------------------------
      pilotHistory: [
        {
          event_id: undefined,
          pilot_id: undefined,
          user_uuid: undefined,
          drone_lab_id: undefined,
          id: undefined,
        },
      ],
      pilotHistoryEvents: [
        {
          date_end: undefined,
          date_start: undefined,
          evaluation: undefined,
          event_id: undefined,
          event_name: undefined,
          reason: undefined,
          request_status: undefined,
          user_uuid: undefined,
        },
      ],
      pilotHistoryUsers: [
        {
          first_name: undefined,
          last_name: undefined,
          organization: undefined,
          email: undefined,
          phone_num: undefined,
          role: undefined,
          uuid: undefined,
        },
      ],
      //-----------------------------------------IDK-------------------------
      selectedOrganization: undefined,
      plusPhone: "+36",
      selectedNum: undefined,
      selectedLevel: "Licence level",
      selectedOrg: "Organisation",
      selectedBought: "Bought",

      /* -----------EVENT------------------- */
      eventUser: {
        first_name: undefined,
        last_name: undefined,
        email: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      clickedEventDrone: [],
      clickedEventPilot: [],
      clickedEventBattery: [],
      /* -----------------------USER DATA -------------------------------*/
      phoneNumPlaceholder: undefined,
      phoneselectedPlaceholder: undefined,
      user: localStorage.getItem("username"),
      currentUser: {
        firstName: undefined,
        lastName: undefined,
        userName: undefined,
        email: undefined,
        phoneNum: undefined,
        organization: undefined,
        role: undefined,
        uuid: undefined,
      },
      batteryType1: undefined,
      batteryTypeLength: undefined,
      /* -----------------------  EDIT DRONE -------------------------------*/

      editDroneName: undefined,
      editDroneType: undefined,
      editDroneSerialNum: undefined,
      editDroneManufacturer: undefined,
      editDroneRegNum: undefined,
      editStartInsur: undefined,
      editEndInsur: undefined,
      editDroneInsurNum: undefined,
      editDroneLabId: undefined,
      editDroneBatteryType: undefined,
      editDroneBatteryConnector: undefined,
      editReqBatteries: undefined,
      editDroneLocal: undefined,
      editDroneComment: undefined,
      editDroneBought: undefined,

      ///---------------------------EDIT USERS------------------------------
      editUsername: undefined,
      editPassword: undefined,
      editPasswordAgain: undefined,
      editEmail: undefined,
      editPhoneNum: undefined,
      editFirstName: undefined,
      editLastName: undefined,
      editOrganization: undefined,
      /* -----------------------  EDIT BATTERY -------------------------------*/

      editBatteryCellNum: undefined,
      editBatteryCapacity: undefined,
      editBatteryManufacturer: undefined,
      editBatteryType: undefined,
      editBatteryLabId: undefined,
      editBatteryConnector: undefined,
      editBatteryName: undefined,
      editBatteryTechnology: undefined,
      editBatteryLocal: undefined,
      editBatteryCValue: undefined,
      /* -----------------------  EDIT PILOT -------------------------------*/
      editPilotName: undefined,
      editLicenceId: undefined,
      editLicenceLevel: undefined,
      editPilotEmail: undefined,
      editPilotPhoneNumber: undefined,
      editPilotOrg: undefined,

      /* -----------------------  SELECTED DRONE -------------------------------*/
      selectedDrone: {
        name: undefined,
        type: undefined,
        serial_num: undefined,
        manufacturer: undefined,
        registration_num: undefined,
        start: undefined,
        end: undefined,
        number: undefined,
        lab_id: undefined,
        connector: undefined,
        required_batteries: undefined,
        localization: undefined,
        id: undefined,
        comment: undefined,
        bought: undefined,
        index: undefined,
        old_lab_id: undefined,
        deleted: undefined,
      },
      selectedBattery: {
        cell_num: undefined,
        capacity: undefined,
        manufacturer: undefined,
        type: undefined,
        lab_id: undefined,
        connector: undefined,
        name: undefined,
        technology: undefined,
        localization: undefined,
        c_value: undefined,
        index: undefined,
        old_lab_id: undefined,
        deleted: undefined,
      },

      selectedPilot: {
        firstname: undefined,
        lastname: undefined,
        licence_id: undefined,
        licence_level: undefined,
        email: undefined,
        phone_num: undefined,
        organization: undefined,
        deleted: undefined,
      },
      selectedUser: {
        firstname: undefined,
        lastname: undefined,
        role: undefined,
        email: undefined,
        phone_num: undefined,
        organization: undefined,
        uuid: undefined,
        deleted: undefined,
      },
      selectedEvent: {
        eventName: undefined,
        dateStart: undefined,
        dateEnd: undefined,
        reason: undefined,
      },
      /* -----------------------LIST VIEW -------------------------------*/
      droneListShow: true,
      batteryListShow: false,
      pilotListShow: false,
      userListShow: false,
      eventListShow: false,

      droneArray: [],
      eventArray: [],
      batteryArray: [],
      pilotArray: [],
      userArray: [],
      droneImageArray: [],
      pilotImageArray: [],
    };
  },
};
</script>

<style>
.listPage-input {
  width: 54%;
  float: right;
}
.new-user-input3 {
  width: 54%;
  float: right;
}
.edit-drone {
  width: 54%;
  float: right;
}
.blackTextCard {
  color: #000;
}
.eventImagesButton {
  margin-right: 10px;
}
.modalContentDarkList {
  background-color: #212529;
}

#eventMoreInfo {
  overflow: auto;
  color: #000;
}
#eventMoreInfoDialog {
  min-width: 100%;
  min-height: 100%;
}
.cardimgtop {
  height: 250px;
  object-fit: cover;
}
.thTableStyle {
  max-width: 100px;
}
#modalBodyBlack {
  color: #000;
}
.modalContExtra {
  background-color: rgb(25, 25, 25, 0.8);
}
.modalFooterList {
  margin-bottom: 10px;
}
.largeModalList {
  min-width: 90%;
}
#editPhoneNumSelectInput2 {
  max-width: 55px;
}
#droneHistory {
  overflow: auto;
}
#pilotHistory {
  overflow: auto;
}
#userMoreInfo {
  overflow: auto;
  z-index: 1062 !important;
}
.modalFooterExtra {
  height: 70px;
}
#exampleModalEdit {
  overflow: auto;
  z-index: 1063 !important;
}
#exampleBatteryModalEdit {
  overflow: auto;
  z-index: 1063 !important;
}
#examplePilotModalEdit {
  overflow: auto;
  z-index: 1063 !important;
}
#eventListShowTableId {
  color: #fff;
  background-color: rgb(59, 58, 58, 0.8);
  overflow-y: scroll;
  vertical-align: center;
}
#pilotMoreInfo {
  overflow: auto;
  z-index: 1062 !important;
}
#batteryMoreInfo {
  overflow: auto;
  z-index: 1062 !important;
}
#droneMoreInfo {
  overflow: auto;
  z-index: 1062 !important;
}
#batteryImageUpload {
  float: right;
}

#cardBodyListAuto {
  margin: auto;
}
#userListCardId {
  min-width: 350px;
  max-width: 350px;
  margin-top: 30px;
  margin-right: 10px;
  top: -70px;
}
#anotherUserEmailInputList {
  width: 54%;
}
#anotherUserFirstNameInputList {
  width: 54%;
}
#anotherUserLastNameInputList {
  width: 54%;
}
#passwordInputAnotherList {
  width: 54%;
}
#droneImageUpload {
  float: right;
}
#phoneTwoList {
  width: 45px;
}
#plusPhoneAnotherList {
  width: 55px;
}
#anotherUserphoneNumInput2List {
  width: 85px;
}
#organizationInputAnotherList {
  width: 54%;
}
#passwordAgainInputAnotherList {
  width: 54%;
}
#roleInput2List {
  width: 54%;
}
#anotherUserEditModalList {
  overflow: auto;
  z-index: 1063 !important;
}
#plusPhoneList {
  width: 55px;
}
#allUsersTableList {
  font-size: 14px;
}
#deleteUserButtonList {
  font-size: 14px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-radius: 12px;
  height: 40px;
}
#deleteUserButtonList:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}
#editUserButtonList {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  font-size: 14px;
  border-radius: 12px;
  height: 40px;
}
#editUserButtonList:hover {
  background-color: rgb(99, 116, 69);
}
.dropdown-item {
  cursor: pointer;
}
.listViewContainer {
  margin-top: 60px;
}
#usersButtonList {
  width: 50px;
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 10px;
}
#usersButtonList:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#logoutButton2List {
  width: 50px;
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  float: right;
  right: 5px;
  margin-right: 10px;
}
#logoutButton2List:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}
#userEditButtonList {
  width: 50px;
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 10px;
  margin-right: 10px;
}
#userEditButtonList:hover {
  background-color: #4f5c72;
  border-color: #4f5c72;
}

#homeButtonList {
  width: 50px;
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 10px;
  margin-right: 10px;
}
#homeButtonList:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#basketButtonList {
  width: 50px;
  border-radius: 50px;
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  top: 10px;
  left: 10px;
  margin-right: 10px;
}
#basketButtonList:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
.fa-circle-info {
  color: #6d6875;
}
.moreInfoButton {
  background-color: #212529;
  color: #fff;
}
.moreInfoButton:hover {
  color: #fff;
  background-color: #6d6875;
}

#userMoreInfoCloseButton {
  float: right;
}
#userMoreInfoEditButton {
  float: left;
}
#userMoreInfoDeleteButton {
  float: left;
}
.eventListShowTable td {
  vertical-align: center;
}
.large {
  max-width: 100vw;
  width: 90vw;
}
.allUsersList {
  max-height: 500px;
  overflow: auto;
  color: #fff;
  width: 100%;
  margin: auto;
}
#allUsersList td {
  font-weight: normal;
  max-height: 5px;
  padding: 5px;
  margin: auto;
  font-size: 14px;
  color: #fff;
}
#allUsersList th {
  padding: 5px;
  font-size: 14px;
  color: #fff;
}

#roleButton {
  font-size: 14px;
  color: #fff;
}
#deleteUser {
  font-size: 14px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-radius: 12px;
  height: 40px;
}
#deleteUser:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}
#eventDateInfo {
  left: 190px;
  top: -30px;
  position: relative;
}

.moreInfoCard {
  max-width: 150px;
  margin-right: 10px;
  color: black;
  text-align: center;
  padding-bottom: 10px;
  margin-bottom: 10px;
  border: 2px, solid, #000;
  cursor: pointer;
}
.new-user-input3 {
  float: right;
}
#phoneNumInput2 {
  max-width: 90px;
}

#pwEyeListPage {
  bottom: 0px;
}
#pwSlashedEyeListPage {
  display: none;
  bottom: 0px;
  position: relative;
}
#pwAgainEyeListPage {
  bottom: 0px;
  position: relative;
}
#pwAgainSlashedEyeListPage {
  display: none;
  bottom: 0px;
  position: relative;
}

#showPasswordButtonListPage {
  position: absolute;
  left: 355px;
  bottom: 245px;
}
#showPasswordAgainButtonListPage {
  position: absolute;
  left: 355px;
  bottom: 190px;
}
.eyeButtonListPage {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  color: black;
  position: relative;
  bottom: 28px;
  float: right;
  z-index: 2;
  margin: 2px;
  min-height: 24px;
  min-width: 20px;
}

.eventMoreInfoButton {
  border-color: #212529;
  background-color: #212529;
}
.eventMoreInfoButton:hover {
  border-color: #6d6875;
  background-color: #6d6875;
}

.eventTable {
  margin-top: -60px;
  color: #fff;
  vertical-align: center;
}
.eventTable td {
  text-align: center;
  color: #fff;
  vertical-align: baseline;
}
.eventTable th {
  text-align: center;
  color: #fff;
  vertical-align: center;
}
.btn-success {
  color: #fff;
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
}
.btn-success:hover {
  background-color: rgb(99, 116, 69);
}
.btn-danger {
  margin-right: 0px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
.btn-danger:hover {
  background-color: rgb(144, 55, 64);
}
.searchBar {
  margin: 0;
  padding: 0;
  position: relative;
  left: 410px;
  top: -70px;
}
#searchButton {
  position: relative;

  max-height: 40px;
}
.searchBarInput {
  height: 40px;
  width: 220px;
}
.searchBarContainer {
  width: 400px;
  margin: 0;
  padding: 0;
}

.searchBarHistory {
  margin: 0;
  padding-bottom: 30px;
  position: relative;
  left: 10px;
  top: 0px;
}
#eventHistoryInfo {
  position: relative;
  top: -30px;
  left: 190px;
}
.searchBarInputHistory {
  height: 40px;
  width: 220px;
}
.searchBarContainerHistory {
  width: 400px;
  margin: 0;
  padding: 0;
}

#droneHistoryList {
  margin-top: -70px;
  overflow: auto;
}
.searchBarHistoryBattery {
  margin: 0;
  padding: 0;
  position: relative;
  left: 10px;
  top: 0px;
}
#eventHistoryInfoBattery {
  position: relative;
  top: -30px;
  left: 190px;
}
.searchBarInputHistoryBattery {
  height: 40px;
  width: 220px;
}
.searchBarContainerHistoryBattery {
  width: 400px;
  margin: 0;
  padding: 0;
}
.searchBarHistoryPilot {
  margin: 0;
  padding: 0;
  position: relative;
  left: 10px;
  top: 0px;
}
#eventHistoryInfoPilot {
  position: relative;
  top: -30px;
  left: 190px;
}
.searchBarInputHistoryPilot {
  height: 40px;
  width: 220px;
}
.searchBarContainerHistoryPilot {
  width: 400px;
  margin: 0;
  padding: 0;
}
.calendarPageDropdownMenu {
  max-height: 150px;
  overflow-y: auto;
  width: 10%;
}
select:invalid {
  color: gray;
}
.modal-footer {
  height: 60px;
}
.btn-close-white {
  color: #fff;
  opacity: 1;
}

.dropdown-toggle::after {
  float: right;
  margin-top: 10px;
}
.edit-drone {
  float: right;
}
.edit-battery {
  float: right;
}
.edit-pilot {
  float: right;
  width: 54%;
}
#listPageEditButton {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
}
#listPageEditButton:hover {
  background-color: rgb(99, 116, 69);
}
#sureButtonCreateList {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  margin-right: 275px;
}
#sureButtonCreateList:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonCreateList {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  margin-right: -10px;
}
#cancelButtonCreateList:hover {
  background-color: rgb(144, 55, 64);
}
#sureButtonCreateListBattery {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  margin-right: 265px;
}
#sureButtonCreateListBattery:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonCreateListBattery {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  margin-right: 0px;
}
#cancelButtonCreateListBattery:hover {
  background-color: rgb(144, 55, 64);
}
#sureButtonCreateListPilot {
  background-color: rgb(64, 76, 43);
  border-color: rgb(64, 76, 43);
  color: #fff;
  margin-right: 130px;
}
#sureButtonCreateListPilot:hover {
  background-color: rgb(99, 116, 69);
}
#cancelButtonCreateListPilot {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  margin-right: 70px;
}
#cancelButtonCreateListPilot:hover {
  background-color: rgb(144, 55, 64);
}
#deleteButton {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
}
#deleteButton:hover {
  background-color: rgb(144, 55, 64);
}
#listPageDeleteButton {
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-color: rgb(110, 40, 47);
  margin-right: 490px;
}
#listPageDeleteButton:hover {
  background-color: rgb(144, 55, 64);
}
#listPageProfile {
  text-align: right;
}
#logoutButtonListPage {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  height: 40px;
}
.allUsersList {
  max-height: 500px;
  overflow: auto;
  color: #fff;
  width: 100%;
  margin: auto;
}
#allUsersList td {
  font-weight: normal;
  max-height: 5px;
  padding: 5px;
  margin: auto;
  font-size: 14px;
  color: #fff;
}
#allUsersList th {
  padding: 5px;
  font-size: 14px;
  color: #fff;
}

#roleButton {
  font-size: 14px;
  color: #fff;
}
#deleteUser {
  font-size: 14px;
  color: #fff;
  background-color: rgb(110, 40, 47);
  border-radius: 12px;
  height: 40px;
}
.techDropdown {
  font-size: 16px;
  height: 40px;
  background-color: #fff;
  color: #4a4a4a;
  width: 54%;
  float: right;
  border-radius: 5px;
}
.large {
  max-width: 100vw;
  width: 90vw;
}
#deleteUser:hover {
  background-color: rgb(144, 55, 64);
  color: #fff;
  border-color: rgb(144, 55, 64);
}
.test-backGround {
  background-color: #212529;
  background-image: url("../assets/img2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 100vh;
  width: 100vw;
  overflow-y: auto;
  overflow-x: hidden;
}
h5 {
  font-weight: bold;
}
.allDroneList {
  margin: 30px;
}
.allBatteryList {
  margin: 30px;
}
.allPilotList {
  margin: 30px;
}
.listPageCard {
  min-width: 350px;
  margin-top: 30px;
  margin-right: 10px;
  top: -70px;
  max-width: 350px;
  width: 16rem;
}
.inactiveText {
  margin-top: 5px;
  margin-left: 5px;
  position: absolute;
  color: #fff;
}
.inactiveTextUser {
  margin-top: 0px;
  margin-left: -90px;
  position: absolute;
  color: #ff0000;
}
.listPageCardNotActive {
  min-width: 350px;
  margin-top: 30px;
  margin-right: 10px;
  top: -70px;
  max-width: 350px;
  width: 16rem;
  filter: grayscale(100%);
}
#batteryHistory {
  overflow: auto;
}
.listPageCardEvent {
  margin-top: 30px;
  min-width: 250px;
  margin-right: 10px;
  max-width: 350px;
}
#droneListShow {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
  position: relative;
  left: 10px;
  top: -30px;
}
#droneListShow:hover {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}
#batteryListShow {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  left: 15px;
  top: -30px;
}
#batteryListShow:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}

#pilotListShow {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  left: 20px;
  top: -30px;
}
#pilotListShow:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#userListShow {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  left: 25px;
  top: -30px;
}
#userListShow:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}
#eventListShow {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
  position: relative;
  left: 30px;
  top: -30px;
}
#eventListShow:hover {
  background-color: #6d6875;
  border-color: #6d6875;
  color: #fff;
}

#historyButton {
  background-color: #212529;
  border-color: #212529;
  color: #fff;
}

#historyButton:hover {
  background-color: #6d6875;
}
.listModal {
  background-color: #212529;
}
.moreTable {
  color: #fff;
}

@media (max-width: 1350px) and (min-width: 1000px) {
  .test-backGround {
    font-size: 12px;
  }
  button#showPasswordButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 237px;
  }
  button#showPasswordAgainButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 190px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #phoneNumInput2 {
    width: 60px;
    padding: 7px;
    height: 30px;
  }
  .new-user-input3 {
    font-size: 10px;
  }
  .btn-success {
    font-size: 12px;
  }
  .btn-danger {
    font-size: 12px;
  }
  input#editPhoneNumSelectInput2 {
    width: 37px;
    height: 30px;
  }
  input#phoneNumInput2 {
    width: 55px;
  }
  h5 {
    font-size: 14px;
  }
  #logoutButtonListPage {
    height: 40px;
  }
}
@media (max-width: 1000px) and (min-width: 530px) {
  .test-backGround {
    font-size: 12px;
  }
  button#showPasswordButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 237px;
  }
  button#showPasswordAgainButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 190px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #phoneNumInput2 {
    width: 60px;
    padding: 7px;
    height: 30px;
  }
  .new-user-input3 {
    font-size: 10px;
  }
  .btn-success {
    font-size: 12px;
  }
  .btn-danger {
    font-size: 12px;
  }
  input#editPhoneNumSelectInput2 {
    width: 37px;
    height: 30px;
  }
  input#phoneNumInput2 {
    width: 55px;
  }
  h5 {
    font-size: 14px;
  }
  #logoutButtonListPage {
    height: 40px;
  }
  #listPageEditButton {
    margin-right: 0px;
  }
  #listPageDeleteButton {
    margin-right: 230px;
  }
}
@media (max-width: 530px) {
  button#showPasswordButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 237px;
  }
  button#showPasswordAgainButtonListPage {
    position: absolute;
    left: 270px;
    bottom: 190px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  #phoneNumInput2 {
    width: 60px;
    padding: 7px;
    height: 30px;
  }
  .new-user-input3 {
    font-size: 10px;
  }
  .btn-success {
    font-size: 12px;
  }
  .btn-danger {
    font-size: 12px;
  }
  input#editPhoneNumSelectInput2 {
    width: 37px;
    height: 30px;
  }
  input#phoneNumInput2 {
    width: 55px;
  }
  .searchBar {
    margin: 0;
    padding: 0;
    position: relative;
    left: 10px;
    top: -20px;
  }
  #searchButton {
    position: relative;

    max-height: 40px;
  }
  .searchBarInput {
    height: 40px;
  }
  .searchBarContainer {
    width: 400px;
    margin: 0;
    padding: 0;
  }

  .test-backGround {
    font-size: 12px;
  }

  h5 {
    font-size: 14px;
  }
  #logoutButtonListPage {
    height: 40px;
  }
  .listPageCard {
    margin-left: -30px;
    width: 320px;
    max-width: 320px;
  }
  .moreInfoCard {
    margin-left: 0px;
    width: 200px;
    max-width: 200px;
  }

  #listPageEditButton {
    margin-right: 10px;
  }
  #listPageDeleteButton {
    margin-right: 50px;
  }
  #exampleModalEdit {
    font-size: 10px;
  }
  .edit-drone {
    font-size: 10px;
    width: 54%;
    float: right;
  }
  .edit-battery {
    font-size: 10px;
  }
  .edit-pilot {
    font-size: 10px;
  }
  .techDropdown {
    font-size: 10px;
    height: 30px;
  }
  button#sureButtonCreateList {
    margin-right: 130px;
  }
  button#cancelButtonCreateList {
    margin-right: 10px;
  }
  button#sureButtonCreateListBattery {
    margin-right: 130px;
  }
  button#cancelButtonCreateListBattery {
    margin-right: 10px;
  }
  button#sureButtonCreateListPilot {
    margin-right: 130px;
  }
  button#cancelButtonCreateListPilot {
    margin-right: 10px;
  }
}
</style>
